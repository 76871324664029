import axios from 'axios';
import { 
  send,
  sendBatch
} from './apps'
const { REACT_APP_NETWORK_PROVIDER} = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || "TestNet"
let auction_api_endpoint = "https://algoapiv1.herokuapp.com" 
export default {
  createSend: async (data) => {
    return (await axios.post(`${auction_api_endpoint}/api/v1/launch2`, send))?.data || 0
  },
  createSendBatch: async (data) => {
    return (await axios.post(`${auction_api_endpoint}/api/v1/launch2`, sendBatch))?.data || 0
  },
}
