import { useEffect, useMemo, useState } from 'react';
import Icon from "react-crypto-icons";
import { loadStdlib } from '@reach-sh/stdlib';
import MyAlgoConnect from '@reach-sh/stdlib/ALGO_MyAlgoConnect';
import WalletConnect from '@reach-sh/stdlib/ALGO_WalletConnect';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import './App.css'
import {
  getAmtForContract as getAmtForContractHelper,
  getAccountInfo,
  getAsset,
  formatMnemonic,
  getHolders,
  getAllAssetBalances,
  formatCompactAddress,
  splitAddrs,
  getRichList,
  searchAssets,
  searchV1
} from './functions';
import { ButtonGroup, Dropdown, Spinner, Table } from 'react-bootstrap';
import logo from './algorand_full_logo_black.svg'
import drop from './drop.svg'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { Autocomplete, Box, Chip, Input, Modal, TextField, Tooltip, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import useCopy from './hooks/useCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useNavigate, useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentCopy from '@mui/icons-material/ContentCopy';
import context from 'react-bootstrap/esm/AccordionContext';
import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SettingsIcon from '@mui/icons-material/Settings';
import BoltIcon from '@mui/icons-material/Bolt';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import debounce from 'lodash.debounce';
import { withMenu } from './hoc/withMenu';
import ProviderSelector from './components/ProviderSelector';

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK, REACT_APP_ADDR } = process.env

const networkEnv = REACT_APP_NETWORK || localStorage.getItem('networkEnv') || "ALGO"
const providerEnv = REACT_APP_NETWORK_PROVIDER || localStorage.getItem('providerEnv') || "MainNet"
const addrEnv = REACT_APP_ADDR

const stdlib = loadStdlib(networkEnv)

console.log(localStorage.getItem('walletFallback'))
if (networkEnv === 'ALGO') {
  if (!localStorage.getItem('walletFallback')) {
    localStorage.setItem('walletFallback', 'Mnemonic')
  }
  if (!localStorage.getItem('settingsWalletFallback')) {
    localStorage.setItem('settingsWalletFallback', 'MyAlgoConnect')
  }
  if (localStorage.getItem('walletFallback') === "MyAlgoConnect") {
    stdlib.setWalletFallback(stdlib.walletFallback({
      providerEnv, MyAlgoConnect
    }));
  }
  else if (localStorage.getItem('walletFallback') === "WalletConnect") {
    stdlib.setWalletFallback(stdlib.walletFallback({
      providerEnv, WalletConnect
    }));
  } else {
    stdlib.setWalletFallback(stdlib.walletFallback({
      providerEnv
    }));
  }
}

const getAmtForContract = getAmtForContractHelper(stdlib)

function Asset() {
  const { id } = useParams()
  const navigate = useNavigate()
  //if(isNaN(parseInt(id)))
  //  window.location = '/'
  const copy = useCopy()
  const initialState = {
    acc: null,
    addrs: [],
    success: false,
    confetti: false,
    drop: false,
    memo: false,
  }
  const [state, setState] = useState(initialState)
  const [close, setClose] = useState(true)
  const [refreshing, setRefreshing] = useState(false)
  const [options, setOptions] = useState([])
  const [holders, setHolders] = useState(null)
  const [blacklist, setBlacklist] = useState(null)
  const [memo, setMemo] = useState(null)
  const [memo2, setMemo2] = useState(null)
  const [chips, setChips] = useState([{ index: 0, name: "ALGO", "unit-name": "ALGO" }])
  const [activeChip, setActiveChip] = useState(0)
  const [query, setQuery] = useState({
    TYPE: 1, // ASA
    METHOD: 1, // LIST
    SKIPCHECK: 1 // SKIP OPTIN CHECK
  })
  useEffect(() => {
    if (!blacklist && !memo && !memo2) {
      let bl = (JSON.parse(localStorage.getItem('state')) ?? {}).blacklist || {}
      let me = (JSON.parse(localStorage.getItem('state')) ?? {}).memo || {}
      let am = (JSON.parse(localStorage.getItem('state')) ?? {}).memo2 || {}
      setBlacklist(bl)
      setMemo(me)
      setMemo2(am)
    }
  }, [])
  useEffect(() => {
    if (!holders) {
      (async () => {
        let rl = await getRichList(id, { limit: 100 });
        console.log(rl)
        setHolders(rl.accounts.map(el => ({
          ...el,
          balance: Number(parseFloat(el.balance) / (10 ** rl['asset-decimals'])).toFixed(2),
          un: rl['asset-unit-name']
        })));
      })();
    }
  }, [id, holders, setHolders])
  // code to build up stored asset info in background
  // Chrome only
  /*
  useEffect(() => {
    if (!state.acc) return
    (async () => {
      let { assets } = state.acc
      for (let i in assets) {
        let asset = assets[i]
        let assetId = asset['asset-id']
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`
        let storedAsset = localStorage.getItem(key)
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo))
          }
        }
      }
    })()
  }, [state.acc])
  */
  // show confetti 
  useEffect(() => {
    if (!state.confetti) return
    let timeout = setTimeout(() => {
      setState({ ...state, confetti: false })
    }, 30000)
  }, [state.confetti])

  const isOptin = (({ optin }) => optin === true)

  const toggleClose = () => setClose(!close)

  const handleChange = async ({ target }) => {
    let { name, value } = target
    console.log({ name, value })
    switch (name) {
      case 'ASSETID':
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId)
          DECIMALS = decimals
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        })
        break
      case 'INFO':
      case 'PASS':
      case 'AMT':
      case 'TYPE':
      case 'METHOD':
      case 'SKIPCHECK':
        value = parseInt(value)
        break
      default:
        break
    }
    setQuery({ ...query, [name]: value })
  }

  const handleConnect = async () => {
    try {
      console.log("Connecting ...")

      let acc
      switch (localStorage.getItem('walletFallback') || 'Mnemonic') {
        case 'MyAlgoConnect':
        case 'WalletConnect':
          acc = await stdlib.getDefaultAccount()
          break
        case 'Mnemonic':
        default:
          let mn = window.prompt("Enter mnemonic phrase")
          console.log('asdf')
          acc = await stdlib.newAccountFromMnemonic(formatMnemonic(mn))
      }

      const addr = stdlib.formatAddress(acc.networkAccount.addr)
      localStorage.setItem('addr', acc.networkAccount.addr)

      const balAtomic = await stdlib.balanceOf(acc)
      const bal = stdlib.formatCurrency(balAtomic, 4)
      const accInfo = await getAccountInfo(addr)
      for (let i in accInfo.assets) {
        let asset = accInfo.assets[i]
        let assetId = asset['asset-id']
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`
        let storedAsset = localStorage.getItem(key)
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo))
          }
        }
      }
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal
      })
    } catch (e) {
      alert(e)
    }
  }

  const handleRefresh = async () => {
    console.log("Refreshing ...")
    try {
      setRefreshing(true)
      const addr = stdlib.formatAddress(state.acc.networkAccount.addr)
      localStorage.setItem('addr', state.acc.networkAccount.addr)
      const balAtomic = await stdlib.balanceOf(state.acc)
      const bal = stdlib.formatCurrency(balAtomic, 4)
      const accInfo = await getAccountInfo(addr)
      for (let i in accInfo.assets) {
        let asset = accInfo.assets[i]
        let assetId = asset['asset-id']
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`
        let storedAsset = localStorage.getItem(key)
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo))
          }
        }
      }
      setRefreshing(false)
      setState({
        ...state,
        acc: {
          ...state.acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal
      })
    } catch (e) {
      alert(e)
    }
  }

  const handleTest = async () => {
    let addrs = splitAddrs(query.ADDRS)

    let assetId = parseInt(query.ASSETID.id)
    let { decimals, unitname } = await getAsset(assetId)
    let candidates = []
    for (let i in addrs) {
      console.log(`Progress: ${i / addrs.length}%`)
      let addr = addrs[i]
      if (query.SKIPCHECK === 0) {
        //let accInfo = await getAccountInfo(addr)
        candidates.push({
          addr,
          optin: null, // accInfo.assets.some(el => el['asset-id'] === assetId)
        })
      } else {
        let accInfo = await getAccountInfo(addr)
        candidates.push({
          addr,
          optin: accInfo.assets.some(el => el['asset-id'] === assetId)
        })
      }
    }
    setState({
      ...state,
      assetId,
      decimals,
      unitname,
      success: false,
      tested: true,
      addrs: candidates
    })
  }

  const handleTableInit = async () => {
    let addrs = splitAddrs(query.ADDRS)
    let candidates = []
    for (let i in addrs) {
      let addr = addrs[i]
      candidates.push({
        addr,
        optin: true
      })
    }
    setState({
      ...state,
      assetId: 0,
      decimals: 6,
      unitname: "ALGO",
      success: false,
      tested: true,
      addrs: candidates
    })
  }


  const handleExecute = async () => {
    try {
      if ((localStorage.getItem('walletFallback') || 'Mnemonic') === 'Mnemonic') {
        let yesNo = window.prompt('Are you sure you want to do this (type yes and click ok button)')
        if (yesNo !== 'yes') return
      }
      let amount = query.TYPE === 0 ? stdlib.parseCurrency(query.AMOUNT) : getAmtForContract(query.AMOUNT, state.decimals)
      let addrs = []
      for (let i in state.addrs) {
        console.log({ i })
        let el = state.addrs[i]
        let success
        let message
        try {
          //if (query.SKIPCHECK === 0 && (query.TYPE === 0 || isOptin(el))) {
          //await new Promise(resolve => setTimeout(resolve, 100))
          if (query.TYPE === 0) {
            await stdlib.transfer(state.acc, el.addr, amount) // Algo transfer succeeds or fails
          } else {
            await stdlib.transfer(state.acc, el.addr, amount, state.assetId) // ASA transfer succeeds or fails
          }
          state.addrs[i].success = true
          setState({ ...state, addrs: state.addrs })
          success = true
          message = ""
          //} else {
          //  success = false
          //  message = "Skipped: missing asset optin"
          //  state.addrs[i].success = false
          //  state.addrs[i].message = message
          //  setState({ ...state, addrs: state.addrs })
          //}
        } catch (e) {
          console.log(e)
          message = "Transfer failed" // <-- it failed
          success = false
          state.addrs[i].success = false
          state.addrs[i].message = message
          setState({ ...state, addrs: state.addrs })
        }
        addrs.push({
          ...el,
          success,
          message
        })
      }
      setState({
        ...state,
        addrs,
        success: true,
        confetti: true
      })
    } catch (e) {
      alert(e)
    }
  }

  const handleRetry = async () => {
    try {
      let yesNo = window.prompt('Are you sure you want to do this (type yes and click ok button)')
      if (yesNo !== 'yes') return
      let amount = query.TYPE === 0 ? stdlib.parseCurrency(query.AMOUNT) : getAmtForContract(query.AMOUNT, state.decimals)
      let addrs = []
      for (let i in state.addrs) {
        console.log({ i })
        let el = state.addrs[i]
        if (el.success) {
          addrs.push(el)
          continue
        }
        let success
        let message
        try {
          //if (query.TYPE === 0 || isOptin(el)) {
          //await new Promise(resolve => setTimeout(resolve, 100))
          if (query.TYPE === 0) {
            await stdlib.transfer(state.acc, el.addr, amount) // Algo transfer succeeds or fails
          } else {
            await stdlib.transfer(state.acc, el.addr, amount, state.assetId) // ASA transfer succeeds or fails
          }
          state.addrs[i].success = true
          setState({ ...state, addrs: state.addrs })
          success = true
          message = ""
          //} else {
          //  success = false
          //  message = "Skipped: missing asset optin"
          //}
        } catch (e) {
          console.log(e)
          message = "Transfer failed" // <-- it failed
          success = false
        }
        addrs.push({
          ...el,
          success,
          message
        })
      }
      setState({
        ...state,
        addrs,
        success: true,
        confetti: true
      })
    } catch (e) {
      alert(e)
    }
  }


  const handleDisconnect = () =>
    setState(initialState)

  const handleProviderSelect = (providerEnv) => {
    localStorage.setItem('providerEnv', providerEnv)
    window.location.reload()
  }

  const handleSupport = async () => {
    let addr = addrEnv
    let inputAmt = window.prompt("Enter ALGO amount to send")
    if (!inputAmt) return
    let amt = stdlib.parseCurrency(inputAmt)
    stdlib.transfer(state.acc, addr, amt)
      .then(() => alert('ALGO recieved! Thank you!'))
  }

  const handleFetch = async () => {
    let assetId = parseInt(query.ASSETID.id)
    let { decimals, unitname } = await getAsset(assetId)
    let addrs = (await getAllAssetBalances(assetId))
      .map(({ address: addr }) => ({ addr, optin: true }))
    setState({
      ...state,
      assetId,
      decimals,
      unitname,
      addrs,
      success: false,
      tested: true,
    })
  }

  const { width, height } = useWindowSize()

  /* autocomplete */
  const eventHandler = (e) => {
    let search = e.target.value
    if (!search) return
    Promise.all([
      searchAssets({ 'unit': String(search) }),
      searchAssets({ 'unit': String(search).toUpperCase() }),
      searchV1(search)
    ])
      .then(el => el.flatMap(el => el.assets || el.assetOutputs))
      .then(setOptions)
  }
  const debouncedEventHandler = useMemo(
    () => debounce(eventHandler, 300)
    , []);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Container className="pb-5">
      {state.confetti && <Confetti
        width={width}
        height={height}
      />}
      <Modal
        open={!close}
        onClose={toggleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Settings modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      <Row className="mt-5">
        <Col>
          <h1 style={{
            fill: "pink",
            color: "black",
            background: "white"
          }} className="text-center">
            <img style={{
              height: '44px',
              verticalAlign: "baseline",
              background: providerEnv === 'MainNet' ? 'deepskyblue' : "darkorange",
              borderRadius: '50px'
            }} src={drop} />
            <span style={{
              color: providerEnv === 'MainNet' ? 'red' : 'green'
            }}>Algo</span>Drop<span style={{
              fontSize: "12px"
            }}>v0.0.5</span></h1>
        </Col>
        <Col className="text-center" xs={12}>
          This runs on<img style={{
            height: '30px'
          }} src={logo} alt="Algorand log" />
          <ProviderSelector />
        </Col>
      </Row>

      {/* wallet here */}

      {state.acc && <Row className="mt-5">
        <Col className="mt-5" xs={12}>
          Connected as{` `}
          <Dropdown as={ButtonGroup}>
            <Button style={{ fill: "white" }} variant='dark' onClick={() => copy(state.addr)}>
              {formatCompactAddress(state.addr)} | <Icon style={{ verticalAlign: "baseline" }} size={11} name="algo" /> {state.bal}
            </Button>
            <Dropdown.Toggle variant="dark" split id="dropdown-basic" />
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleDisconnect}>
                Disconnect
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>{` `}
          <span>
            {!refreshing
              ? <RefreshIcon style={{ verticalAlign: "sub" }} fontSize='small' onClick={handleRefresh} />
              : <Spinner animation="border" variant="secondary" size="sm" />}
          </span>
        </Col>
      </Row>}
      <Row className="mt-5">
        <Col xs={12}>
          <Autocomplete
            className="w-100"
            fullWidth
            multiple
            disablePortal
            id="combo-box-demo"
            getOptionLabel={option => `${option?.assetID || option?.index} : ${option.name || option.assetName} : ${option.unitName || option['unit-name']}`}
            options={options}
            sx={{ width: 300 }}
            onInputChange={debouncedEventHandler}
            renderTags={(value, getTagProps) =>
              value
                .filter(el => !chips.map(el => el.index).includes(el.index))
                .map((option, index) => (
                  <Chip
                    variant='outline'
                    onClick={() => {
                      navigate(`/asset/${option.assetID || option.index}`)
                      setHolders(null)
                    }}
                    label={`${option?.assetID || option?.index} : ${option.name || option.assetName} : ${option.unitName || option['unit-name']}`} {...getTagProps({ index })} />
                ))}
            renderInput={(params) => <TextField {...params} label="Asset" />}

          />
        </Col>
      </Row>
      <Row className="mt-5">

        <ButtonGroup as={Col} xs={12}>
          <Button onClick={() => localStorage.setItem('state', JSON.stringify({
            blacklist,
            memo,
            memo2
          }))}><SaveIcon /></Button>
          <Button variant="warning"><DownloadIcon /></Button>
          <Button variant="success"><FileUploadIcon /></Button>
          <Button variant="danger" onClick={() => {
            localStorage.removeItem('state')
            setHolders(null)
            setBlacklist({})
            setMemo({})
            setMemo2({})
          }}><DeleteIcon /></Button>
          <Button variant="dark"><SettingsIcon /></Button>
        </ButtonGroup>
        <ButtonGroup as={Col} xs={12}>
          <Tooltip title="Memo" arrow>
            <Button variant="secondary" onClick={() => {
              setState({
                ...state,
                memo: !state.memo
              })
            }}><EditIcon /></Button></Tooltip>
          {state.memo && <Tooltip title="Clear Memo" arrow>
            <Button variant="secondary" onClick={() => {
              window.prompt('Are you sure you want to clear your address memos?')
            }}><DeleteIcon /></Button></Tooltip>}
          <Tooltip title="Send ASAs" arrow>
            <Button variant="info" className="text-light" onClick={() => {
              setState({
                ...state,
                drop: !state.drop
              })
            }}><SendIcon /></Button>
          </Tooltip>
          {state.drop &&
            <Tooltip title="Populate drop amounts" arrow>
              <Button variant="info" className="text-light font-weight-bold" onClick={() => {
                holders.forEach(el => {
                  memo2[el.address] = { amount: 1, token: chips[activeChip] }
                })
                setMemo2(memo2)
                setHolders(null)
              }}><BoltIcon /></Button></Tooltip>}
          {state.drop && <Button variant="info" className="text-light" onClick={toggleClose}>
            <SettingsIcon />
            </Button>}
          {state.drop && <Button variant="info" className="text-light" onClick={() => {
            window.prompt('Are you sure you want to clear your drop list?')
          }}><DeleteIcon /></Button>}
        </ButtonGroup>
        {state.drop && <Row className="mt-5">
          <Col xs={12}>
            <Autocomplete
              className="w-100"
              fullWidth
              multiple
              disablePortal
              id="combo-box-demo"
              getOptionLabel={option => option?.params?.verified
                ? `(V) ${option.index} : ${option.name} : ${option['unit-name']}`
                : `${option.index} : ${option.name} : ${option['unit-name']}`}
              options={options}
              sx={{ width: 300 }}
              onInputChange={debouncedEventHandler}
              renderTags={(value, getTagProps) =>
                value
                  .filter(el => !chips.map(el => el.index).includes(el.index))
                  .map((option, index) => (
                    <Chip
                      variant='outline'
                      onClick={() => setChips([...chips, option])}

                      label={`${option.index} : ${option.name} : ${option['unit-name']}`} {...getTagProps({ index })} />
                  ))}
              renderInput={(params) => <TextField {...params} label="Asset" />}

            />
          </Col>
          <Col xs={12}>
            {chips.map((option, index) =>
              <Chip
                key={`chip-${index}`}
                className="mt-3"
                variant={activeChip === index ? "" : "outlined"}
                onClick={() => setActiveChip(index)}
                onDelete={option.index > 0 ? () => setChips([...chips.slice(0, index), ...chips.slice(index + 1)]) : () => { }}
                label={option.index > 0 ? `${option.index} : ${option.name} : ${option['unit-name']}` : "ALGO"} />)}
          </Col>
        </Row>}
        <Col className="mt-5" xs={12}>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Address</th>
                {state.memo && <th colSpan={2}>Memo</th>}
                {state.drop && <>
                  <th>Drop Amount</th>
                  <th>Drop Unit</th>
                </>}

                <th style={{ textAlign: "right" }}>Asset Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {holders && holders
                .filter(el => !(Object.values(blacklist[id] ?? []).includes(el.address)))
                .map(el => ({
                  address: el.address,
                  amount: parseInt(el.balance),
                  un: el.un
                }))
                .map(({ address, amount, un }, i) =>
                  <tr key={address}>
                    <td>{i + 1}</td>
                    <td><a onClick={() => copy(address)}>{formatCompactAddress(address)} <ContentCopy /></a></td>
                    {state.memo && <td colSpan={2}>
                      <input style={{
                        borderWidth: '0px',
                        border: 'none'
                      }} className="w-100"
                        defaultValue={memo[address] || ""}
                        onChange={({ target }) => {
                          let { value } = target
                          setMemo({
                            ...memo,
                            [address]: value
                          })
                        }} />
                    </td>}
                    {state.drop && <>
                      <td>
                        <input
                          type="number"
                          style={{
                            borderWidth: '0px',
                            border: 'none'
                          }} className="w-100"
                          defaultValue={memo2[address]?.amount || ""}
                          onChange={({ target }) => {
                            let { value } = target
                            setMemo2({
                              ...memo2,
                              [address]: { amount: parseInt(value), token: chips[activeChip] }
                            })
                          }} />
                      </td>
                      <td>{(memo2[address]?.token ?? {})["unit-name"]}</td>
                    </>}
                    <td style={{ textAlign: "right", fontWeight: 'bold' }}>{Number(amount).toLocaleString()} {un}</td>
                    <td>
                      <span onClick={() => {
                        if (!(id in blacklist))
                          setBlacklist({
                            ...blacklist,
                            [id]: [address]
                          })
                        else
                          setBlacklist({
                            ...blacklist,
                            [id]: [...blacklist[id], address]
                          })
                      }} style={{ color: "red" }}><DeleteIcon /></span>
                      <FavoriteBorderIcon />
                    </td>
                  </tr>)}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default withMenu(Asset)
