import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const networkEnv = REACT_APP_NETWORK || localStorage.getItem('networkEnv') || "ALGO"
const providerEnv = REACT_APP_NETWORK_PROVIDER || localStorage.getItem('providerEnv') || "MainNet"

export const withMenu = Component => {


    return (props) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const navigate = useNavigate()
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        return <>
            <div>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    MENU
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem style={{
                        background: "black",
                        color: 'white'
                    }} onClick={() => navigate('/')}>AlgoDrop</MenuItem>
                    <MenuItem onClick={() => navigate('/drop')}>💧 DROP</MenuItem>
                    <MenuItem onClick={() => navigate('/super')}>🦸 SUPER DROP 16X</MenuItem>
                    <MenuItem onClick={() => navigate('/holders')}>🤜 HOLDERS</MenuItem>
                    <MenuItem onClick={() => navigate('/accounts')}>🧑‍💻 ACCOUNTS</MenuItem>
                    <MenuItem onClick={() => navigate('/calculator')}>🧮 MIN BALANCE</MenuItem>
                    {providerEnv === 'TestNet' && <MenuItem onClick={() => navigate('/asset/1')}>PLANER (BETA)</MenuItem>}
                    {false && <MenuItem onClick={() => navigate('/vote')}>VOTE (BETA)</MenuItem>}
                    {providerEnv === 'TestNet' && <MenuItem onClick={() => navigate('/send')}>SEND (BETA)</MenuItem>}
                    {providerEnv === 'TestNet' && <MenuItem onClick={() => navigate('/send-batch')}>SEND BATCH (BETA)</MenuItem>}
                    {providerEnv === 'TestNet' && <MenuItem onClick={() => navigate('/cost')}>CONTRACT COST</MenuItem>}
                    <MenuItem onClick={() => navigate('/support')}>🎁 SUPPORT DEV</MenuItem>
                </Menu>
            </div>
            <Component {...props} />
        </>
    }
}