import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Asset from './Asset';
import Calculator from './Calculator';
import Holders from './Holders';
import Support from './Support';
import UnderConstruction from './UnderConstruction';
import Drop from './Drop';
import Send from './Send';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SnackbarProvider } from 'notistack';
import { HashRouter, Route, Routes } from 'react-router-dom'
import Accounts from './Accounts';
import Cost from './Cost';
import SendBatch from './SendBatch';
import Super from './Super';
import Optin from './Optin';
import Mint from './Mint';
const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK } = process.env
const networkEnv = REACT_APP_NETWORK || localStorage.getItem('networkEnv') || "ALGO"
const providerEnv = REACT_APP_NETWORK_PROVIDER || localStorage.getItem('providerEnv') || "MainNet"
ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider maxSnack={1} hideIconVariant>
      <HashRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/drop" element={<Drop />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/holders" element={<Holders />} />
          <Route path="/optin" element={<Optin />} />
          <Route path="/mint" element={<Mint />} />
          <Route path="/support" element={<Support />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/super" element={<Super />} />
          {providerEnv === 'TestNet' && <Route path="/asset/:id" element={<Asset />} />}
          {providerEnv === 'TestNet' && <Route path="/send" element={<Send />} />}
          {providerEnv === 'TestNet' && <Route path="/send-batch" element={<SendBatch />} />}
          {providerEnv === 'TestNet' && <Route path="/cost" element={<Cost />} />}
          {false && <Route path="/vote" element={<UnderConstruction />} />}
        </Routes>
      </HashRouter>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
