import { useEffect, useState } from 'react';
import Icon from "react-crypto-icons";
import { loadStdlib } from '@reach-sh/stdlib';
import { ALGO_MyAlgoConnect as MyAlgoConnect } from "@reach-sh/stdlib";
import { ALGO_WalletConnect as WalletConnect } from "@reach-sh/stdlib";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import './App.css'
import {
  getAmtForContract as getAmtForContractHelper,
  getAccountInfo,
  getAsset,
  formatMnemonic,
  getStoredAsset,
  getAllAssetBalances,
  formatCompactAddress,
  splitAddrs
} from './functions';
import { ButtonGroup, Dropdown, Spinner, Table } from 'react-bootstrap';
import logo from './algorand_full_logo_black.svg'
import drop from './drop.svg'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { Autocomplete, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import useCopy from './hooks/useCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';
import { useHistory, useNavigate } from "react-router-dom";
import { withMenu } from './hoc/withMenu';
import ProviderSelector from './components/ProviderSelector';

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK, REACT_APP_ADDR } = process.env

const networkEnv = REACT_APP_NETWORK || localStorage.getItem('networkEnv') || "ALGO"
const providerEnv = REACT_APP_NETWORK_PROVIDER || localStorage.getItem('providerEnv') || "MainNet"
const addrEnv = REACT_APP_ADDR

const stdlib = loadStdlib(networkEnv)

console.log(localStorage.setItem('walletFallback', 'WalletConnect'))
if (networkEnv === 'ALGO') {
  if (!localStorage.getItem('walletFallback')) {
    localStorage.setItem('walletFallback', 'Mnemonic')
  }
  if (!localStorage.getItem('settingsWalletFallback')) {
    localStorage.setItem('settingsWalletFallback', 'MyAlgoConnect')
  }
  if (localStorage.getItem('walletFallback') === "MyAlgoConnect") {
    stdlib.setWalletFallback(stdlib.walletFallback({
      providerEnv, MyAlgoConnect
    }));
  }
  else if (localStorage.getItem('walletFallback') === "WalletConnect") {
    stdlib.setWalletFallback(stdlib.walletFallback({
      providerEnv, WalletConnect
    }));
  } else {
    stdlib.setWalletFallback(stdlib.walletFallback({
      providerEnv
    }));
  }
}

const getAmtForContract = getAmtForContractHelper(stdlib)

function App() {
  const copy = useCopy()
  const navigate = useNavigate()
  const initialState = {
    acc: null,
    addrs: localStorage.getItem('state') && (Object.keys(JSON.parse(localStorage.getItem('state'))?.memo2) || [])
      .map(el => ({ addr: el })),
    success: false,
    confetti: false,
  }
  const [state, setState] = useState(initialState)
  const [refreshing, setRefreshing] = useState(false)
  const [query, setQuery] = useState({
    PLAN: 0, // Default
    TYPE: 1, // ASA
    METHOD: 1, // LIST
    SKIPCHECK: 1 // SKIP OPTIN CHECK
  })
  // code to build up stored asset info in background
  // Chrome only
  /*
  useEffect(() => {
    if (!state.acc) return
    (async () => {
      let { assets } = state.acc
      for (let i in assets) {
        let asset = assets[i]
        let assetId = asset['asset-id']
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`
        let storedAsset = localStorage.getItem(key)
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo))
          }
        }
      }
    })()
  }, [state.acc])
  */
  // show confetti 
  useEffect(() => {
    if (!state.confetti) return
    let timeout = setTimeout(() => {
      setState({ ...state, confetti: false })
    }, 30000)
  }, [state.confetti])

  const isOptin = (({ optin }) => optin === true)

  const handleChange = async ({ target }) => {
    let { name, value } = target
    console.log({ name, value })
    switch (name) {
      case 'ASSETID':
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId)
          DECIMALS = decimals
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        })
        break
      case 'INFO':
      case 'PASS':
      case 'PLAN':
      case 'AMT':
      case 'TYPE':
      case 'METHOD':
      case 'SKIPCHECK':
        value = parseInt(value)
        break
      default:
        break
    }
    setQuery({ ...query, [name]: value })
  }

  const handleConnect = async () => {
    try {
      console.log("Connecting ...")

      let acc
      switch (localStorage.getItem('walletFallback') || 'Mnemonic') {
        case 'MyAlgoConnect':
        case 'WalletConnect':
          acc = await stdlib.getDefaultAccount()
          break
        case 'Mnemonic':
        default:
          let mn = window.prompt("Enter mnemonic phrase")
          console.log('asdf')
          acc = await stdlib.newAccountFromMnemonic(formatMnemonic(mn))
      }

      const addr = stdlib.formatAddress(acc.networkAccount.addr)
      localStorage.setItem('addr', acc.networkAccount.addr)

      const balAtomic = await stdlib.balanceOf(acc)
      const bal = stdlib.formatCurrency(balAtomic, 4)
      const accInfo = await getAccountInfo(addr)
      for (let i in accInfo.assets) {
        let asset = accInfo.assets[i]
        let assetId = asset['asset-id']
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`
        let storedAsset = localStorage.getItem(key)
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo))
          }
        }
      }
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal
      })
    } catch (e) {
      alert(e)
    }
  }

  const handleRefresh = async () => {
    console.log("Refreshing ...")
    try {
      setRefreshing(true)
      const addr = stdlib.formatAddress(state.acc.networkAccount.addr)
      localStorage.setItem('addr', state.acc.networkAccount.addr)
      const balAtomic = await stdlib.balanceOf(state.acc)
      const bal = stdlib.formatCurrency(balAtomic, 4)
      const accInfo = await getAccountInfo(addr)
      for (let i in accInfo.assets) {
        let asset = accInfo.assets[i]
        let assetId = asset['asset-id']
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`
        let storedAsset = localStorage.getItem(key)
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo))
          }
        }
      }
      setRefreshing(false)
      setState({
        ...state,
        acc: {
          ...state.acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal
      })
    } catch (e) {
      alert(e)
    }
  }

  const handleTest = async () => {
    let addrs = splitAddrs(query.ADDRS)

    let assetId = parseInt(query.ASSETID.id)
    let { decimals, unitname } = await getAsset(assetId)
    let candidates = []
    for (let i in addrs) {
      console.log(`Progress: ${i / addrs.length}%`)
      let addr = addrs[i]
      if (query.SKIPCHECK === 0) {
        //let accInfo = await getAccountInfo(addr)
        candidates.push({
          addr,
          optin: null, // accInfo.assets.some(el => el['asset-id'] === assetId)
        })
      } else {
        let accInfo = await getAccountInfo(addr)
        candidates.push({
          addr,
          optin: accInfo.assets.some(el => el['asset-id'] === assetId)
        })
      }
    }
    setState({
      ...state,
      assetId,
      decimals,
      unitname,
      success: false,
      tested: true,
      addrs: candidates
    })
  }

  const handleTableInit = async () => {
    let addrs = splitAddrs(query.ADDRS)
    let candidates = []
    for (let i in addrs) {
      let addr = addrs[i]
      candidates.push({
        addr,
        optin: true
      })
    }
    setState({
      ...state,
      assetId: 0,
      decimals: 6,
      unitname: "ALGO",
      success: false,
      tested: true,
      addrs: candidates
    })
  }


  const handleExecute = async () => {
    try {
      if ((localStorage.getItem('walletFallback') || 'Mnemonic') === 'Mnemonic') {
        let yesNo = window.prompt('Are you sure you want to do this (type yes and click ok button)')
        if (yesNo !== 'yes') return
      }
      let amount = query.TYPE === 0 ? stdlib.parseCurrency(query.AMOUNT) : getAmtForContract(query.AMOUNT, state.decimals)
      let addrs = []
      for (let i in state.addrs) {
        console.log({ i })
        let el = state.addrs[i]
        let success
        let message
        try {
          //if (query.SKIPCHECK === 0 && (query.TYPE === 0 || isOptin(el))) {
          //await new Promise(resolve => setTimeout(resolve, 100))
          if (query.TYPE === 0) {
            await stdlib.transfer(state.acc, el.addr, amount) // Algo transfer succeeds or fails
          } else {
            await stdlib.transfer(state.acc, el.addr, amount, state.assetId) // ASA transfer succeeds or fails
          }
          state.addrs[i].success = true
          setState({ ...state, addrs: state.addrs })
          success = true
          message = ""
          //} else {
          //  success = false
          //  message = "Skipped: missing asset optin"
          //  state.addrs[i].success = false
          //  state.addrs[i].message = message
          //  setState({ ...state, addrs: state.addrs })
          //}
        } catch (e) {
          console.log(e)
          message = "Transfer failed" // <-- it failed
          success = false
          state.addrs[i].success = false
          state.addrs[i].message = message
          setState({ ...state, addrs: state.addrs })
        }
        addrs.push({
          ...el,
          success,
          message
        })
      }
      setState({
        ...state,
        addrs,
        success: true,
        confetti: true
      })
    } catch (e) {
      alert(e)
    }
  }

  const handleRetry = async () => {
    try {
      let yesNo = window.prompt('Are you sure you want to do this (type yes and click ok button)')
      if (yesNo !== 'yes') return
      let amount = query.TYPE === 0 ? stdlib.parseCurrency(query.AMOUNT) : getAmtForContract(query.AMOUNT, state.decimals)
      let addrs = []
      for (let i in state.addrs) {
        console.log({ i })
        let el = state.addrs[i]
        if (el.success) {
          addrs.push(el)
          continue
        }
        let success
        let message
        try {
          //if (query.TYPE === 0 || isOptin(el)) {
          //await new Promise(resolve => setTimeout(resolve, 100))
          if (query.TYPE === 0) {
            await stdlib.transfer(state.acc, el.addr, amount) // Algo transfer succeeds or fails
          } else {
            await stdlib.transfer(state.acc, el.addr, amount, state.assetId) // ASA transfer succeeds or fails
          }
          state.addrs[i].success = true
          setState({ ...state, addrs: state.addrs })
          success = true
          message = ""
          //} else {
          //  success = false
          //  message = "Skipped: missing asset optin"
          //}
        } catch (e) {
          console.log(e)
          message = "Transfer failed" // <-- it failed
          success = false
        }
        addrs.push({
          ...el,
          success,
          message
        })
      }
      setState({
        ...state,
        addrs,
        success: true,
        confetti: true
      })
    } catch (e) {
      alert(e)
    }
  }


  const handleDisconnect = () =>
    setState(initialState)

  const handleProviderSelect = (providerEnv) => {
    localStorage.setItem('providerEnv', providerEnv)
    window.location.reload()
  }

  const handleSupport = async () => {
    let addr = addrEnv
    let inputAmt = window.prompt("Enter ALGO amount to send")
    if (!inputAmt) return
    let amt = stdlib.parseCurrency(inputAmt)
    stdlib.transfer(state.acc, addr, amt)
      .then(() => alert('ALGO recieved! Thank you!'))
  }

  const handleFetch = async () => {
    let assetId = parseInt(query.ASSETID.id)
    let { decimals, unitname } = await getAsset(assetId)
    let addrs = (await getAllAssetBalances(assetId))
      .map(({ address: addr }) => ({ addr, optin: true }))
    setState({
      ...state,
      assetId,
      decimals,
      unitname,
      addrs,
      success: false,
      tested: true,
    })
  }



  const { width, height } = useWindowSize()

  return (
    <Container className="pb-5">
      {state.confetti && <Confetti
        width={width}
        height={height}
      />}
      <Row className="mt-5">
        <Col>
          <h1 style={{
            fill: "pink",
            color: "black",
            background: "white"
          }} className="text-center">
            <img style={{
              height: '44px',
              verticalAlign: "baseline",
              background: providerEnv === 'MainNet' ? 'deepskyblue' : "darkorange",
              borderRadius: '50px'
            }} src={drop} />
            <span style={{
              color: providerEnv === 'MainNet' ? 'red' : 'green'
            }}>Algo</span>Drop<span style={{
              fontSize: "12px"
            }}>v0.0.5</span></h1>
        </Col>
        <Col className="text-center" xs={12}>
          This runs on<img style={{
            height: '30px'
          }} src={logo} alt="Algorand log" />
          <ProviderSelector />
        </Col>
        <Col className="mt-5" xs={12}>
          <section class="vh-100 bg-light">
            <div class="container py-5 h-100">
              <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col col-lg-10 col-xl-8">
                  <figure class="note note-primary p-4">
                    <blockquote class="blockquote">
                      <p class="pb-2">
                      "We used the AlgoDrops to execute over 6000 AirDrops for <a href="https://algoexplorer.io/asset/513016520" target="_blank" rel="noopener noreferrer nofollow">$STK</a>. One of the best airdrop scripts on Algorand, great interface and a quick to respond team in case you run into any problems or questions!"
                      </p>
                    </blockquote>
                    <figcaption class="blockquote-footer mb-0">
                    <a href="https://www.linkedin.com/in/sara-jane-kenny-82a50a1ba/" target="_blank" rel="noopener noreferrer nofollow">Sara Jane</a>
                    </figcaption>
                  </figure>
                  <figure class="note note-secondary p-4">
                    <blockquote class="blockquote">
                      <p class="pb-2">
                        "We used the AlgoDrops tool execute over 600 AirDrops for <a href="https://algoexplorer.io/asset/473180477" target="_blank" rel="noopener noreferrer nofollow">$BEAN</a> and <a href="https://algoexplorer.io/asset/417708610" target="_blank" rel="noopener noreferrer nofollow">$DEGEN</a> Holders. Worked great and support was on hand to solve our issues quickly!"
                      </p>
                    </blockquote>
                    <figcaption class="blockquote-footer mb-0">
                    <a href="https://twitter.com/agashnava" target="_blank" rel="noopener noreferrer nofollow">@agashnava</a>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>
          </section>
        </Col>
      </Row>

    </Container>
  );
}

export default withMenu(App);