import { borderRadius } from "@mui/system"
import { Dropdown } from "react-bootstrap"
const { REACT_APP_NETWORK_PROVIDER } = process.env
const providerEnv = REACT_APP_NETWORK_PROVIDER || localStorage.getItem('providerEnv') || "MainNet"
const handleProviderSelect = (providerEnv) => {
    localStorage.setItem('providerEnv', providerEnv)
    window.location.reload()
}
const ProviderSelector = () => <Dropdown className="d-inline">
    <Dropdown.Toggle style={{
        background: providerEnv === "MainNet" ? "red" : "green",
        border: "0px",
        borderRadius: "0px",
        padding: "1px 10px",
    }} variant="secondary" id="dropdown-basic">
        {providerEnv.toUpperCase()}
    </Dropdown.Toggle>
    <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleProviderSelect('MainNet')}>MainNet</Dropdown.Item>
        <Dropdown.Item onClick={() => handleProviderSelect('TestNet')}>TestNet</Dropdown.Item>
    </Dropdown.Menu>
</Dropdown>
export default ProviderSelector