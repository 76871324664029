import { useEffect, useMemo, useState } from "react";
import Icon from "react-crypto-icons";
import moment from "moment";
import { loadStdlib } from "@reach-sh/stdlib";
import { ALGO_MyAlgoConnect as MyAlgoConnect } from "@reach-sh/stdlib";
import { ALGO_WalletConnect as WalletConnect } from "@reach-sh/stdlib";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./App.css";
import {
  getAmtForContract as getAmtForContractHelper,
  getAccountInfo,
  getAsset,
  formatMnemonic,
  getHolders,
  getAllAssetBalances,
  formatCompactAddress,
  splitAddrs,
  getRichList,
  searchAssets,
  searchV1,
  getMinBalance,
  getAccountAssets,
} from "./functions";
import { ButtonGroup, Dropdown, Spinner, Table } from "react-bootstrap";
import logo from "./algorand_full_logo_black.svg";
import drop from "./drop.svg";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Input,
  Modal,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import useCopy from "./hooks/useCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate, useParams } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCopy from "@mui/icons-material/ContentCopy";
import context from "react-bootstrap/esm/AccordionContext";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SettingsIcon from "@mui/icons-material/Settings";
import BoltIcon from "@mui/icons-material/Bolt";
import ClearIcon from "@mui/icons-material/Clear";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import debounce from "lodash.debounce";
import { withMenu } from "./hoc/withMenu";
import ProviderSelector from "./components/ProviderSelector";
import { useRef } from "react";
import { default as MAC } from "@randlabs/myalgo-connect";
import algosdk from "algosdk";

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK, REACT_APP_ADDR } =
  process.env;

const networkEnv =
  REACT_APP_NETWORK || localStorage.getItem("networkEnv") || "ALGO";

const providerType =
  REACT_APP_NETWORK_PROVIDER ||
  localStorage.getItem("providerEnv") ||
  "MainNet";

let providerEnv;
switch (providerType) {
  case "MainNet":
  case "MainNet-RandLabs":
  case "MainNet-AlgoNode":
    providerEnv = {
      ALGO_TOKEN: "",
      ALGO_SERVER: "https://mainnet-api.algonode.cloud",
      ALGO_PORT: "",
      ALGO_NODE_WRITE_ONLY: "no",

      ALGO_INDEXER_TOKEN: "",
      ALGO_INDEXER_SERVER: "https://mainnet-idx.algonode.cloud",
      ALGO_INDEXER_PORT: "",
    };
    break;
  case "TestNet":
  case "TestNet-RandLabs":
  case "TestNet-AlgoNode":
    providerEnv = {
      ALGO_TOKEN: "",
      ALGO_SERVER: "https://testnet-api.algonode.cloud",
      ALGO_PORT: "",
      ALGO_NODE_WRITE_ONLY: "no",

      ALGO_INDEXER_TOKEN: "",
      ALGO_INDEXER_SERVER: "https://testnet-idx.algonode.cloud",
      ALGO_INDEXER_PORT: "",
    };
    break;
}

const addrEnv = REACT_APP_ADDR;

const stdlib = loadStdlib(networkEnv);

localStorage.setItem("walletFallback", "WalletConnect");
console.log(localStorage.getItem("walletFallback"));
if (networkEnv === "ALGO") {
  if (!localStorage.getItem("walletFallback")) {
    localStorage.setItem("walletFallback", "Mnemonic");
  }
  if (!localStorage.getItem("settingsWalletFallback")) {
    localStorage.setItem("settingsWalletFallback", "MyAlgoConnect");
  }
  if (localStorage.getItem("walletFallback") === "MyAlgoConnect") {
    stdlib.setWalletFallback(
      stdlib.walletFallback({
        providerEnv,
        MyAlgoConnect,
      })
    );
  } else if (localStorage.getItem("walletFallback") === "WalletConnect") {
    stdlib.setWalletFallback(
      stdlib.walletFallback({
        providerEnv,
        WalletConnect,
      })
    );
  } else {
    stdlib.setWalletFallback(
      stdlib.walletFallback({
        providerEnv,
      })
    );
  }
}

function spliceIntoChunks(arr, chunkSize) {
  const res = [];
  while (arr.length > 0) {
    const chunk = arr.splice(0, chunkSize);
    res.push(chunk);
  }
  return res;
}

const getAmtForContract = getAmtForContractHelper(stdlib);

function Optin() {
  const { id } = useParams();
  const navigate = useNavigate();
  //if(isNaN(parseInt(id)))
  //  window.location = '/'
  const copy = useCopy();
  const initialState = {
    acc: null,
    addrs: [],
    success: false,
    confetti: false,
    drop: false,
    memo: false,
  };
  const [assets, setAssets] = useState(null);
  const [bal, setBal] = useState(0.0);
  const [state, setState] = useState(initialState);
  const [close, setClose] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [options, setOptions] = useState([]);
  const [holders, setHolders] = useState(null);
  const [blacklist, setBlacklist] = useState(null);
  const [memo, setMemo] = useState(null);
  const [memo2, setMemo2] = useState(null);
  const [chips, setChips] = useState([
    { index: 0, name: "ALGO", "unit-name": "ALGO" },
  ]);
  const [activeChip, setActiveChip] = useState(0);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [requireBalance, setRequireBalance] = useState(true);
  const [query, setQuery] = useState({
    TYPE: 1, // ASA
    METHOD: 1, // LIST
    SKIPCHECK: 1, // SKIP OPTIN CHECK
    REQUIREBALANCE: true, // REQUIRE BAL GT 0
  });

  // code to build up stored asset info in background
  // Chrome only
  /*
  useEffect(() => {
    if (!state.acc) return
    (async () => {
      let { assets } = state.acc
      for (let i in assets) {
        let asset = assets[i]
        let assetId = asset['asset-id']
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`
        let storedAsset = localStorage.getItem(key)
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo))
          }
        }
      }
    })()
  }, [state.acc])
  */

  const isOptin = ({ optin }) => optin === true;

  const toggleClose = () => setClose(!close);

  const optinASA = async (assets) => {
    const fromAddr = state.acc.networkAccount.addr;
    console.log({
      fromAddr,
      assets,
    });
    const myAlgoWallet = new MAC();
    const algodClient = new algosdk.Algodv2(
      "",
      providerType === "MainNet"
        ? "https://node.algoexplorerapi.io"
        : "https://node.testnet.algoexplorerapi.io",
      ""
    );
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder(); // always utf-8
    let txns = assets.map((assetIndex) =>
      algosdk.makeAssetTransferTxnWithSuggestedParams(
        fromAddr,
        fromAddr,
        undefined,
        undefined,
        0,
        undefined,
        assetIndex,
        params
      )
    );
    let txgroup = algosdk.assignGroupID(txns, fromAddr);
    console.log({ txns });
    let stx = await myAlgoWallet.signTransaction(
      txgroup.map((el) => el.toByte())
    );
    let res = await algodClient
      .sendRawTransaction(stx.map((el) => el.blob))
      .do();

    console.log(res);
    stdlib.wait(10);
  };

  const handleMint = async () => {
    console.log(state.acc);
    const mintParams = JSON.parse(query.NAME);
    const opt = ({
      decimals: 0,
        supply: 1,
        url: mintParams.image,
        note: new TextEncoder().encode(JSON.stringify(mintParams.metadata))
    })
    console.log(opt);
    await stdlib.launchToken(
      state.acc,
      mintParams.name,
      mintParams.sym,
      opt
    );
  };

  const handleOptins = async () => {
    const walletAssets = state.acc.assets.map((el) => el["asset-id"]);
    const listAssets = assets.split(",").map((el) => parseInt(el));
    const remainingAssets = listAssets.filter(
      (el) => !walletAssets.includes(el)
    );
    console.log(walletAssets);
    console.log(listAssets);
    console.log(remainingAssets);
    const chunks = spliceIntoChunks(remainingAssets, 16);
    for (let i in chunks) {
      const chunk = chunks[i];
      await optinASA(chunk);
    }
  };

  const handleConnect = async () => {
    try {
      console.log("Connecting ...");

      let acc;
      switch (localStorage.getItem("walletFallback") || "MyAlgoConnect") {
        case "MyAlgoConnect":
        case "WalletConnect":
          acc = await stdlib.getDefaultAccount();
          break;
        case "Mnemonic":
        default:
          let mn = window.prompt("Enter mnemonic phrase");
          acc = await stdlib.newAccountFromMnemonic(formatMnemonic(mn));
      }
      console.log(acc);

      const addr = stdlib.formatAddress(acc.networkAccount.addr);
      localStorage.setItem("addr", acc.networkAccount.addr);

      //const balAtomic = await stdlib.balanceOf(acc);
      //const bal = stdlib.formatCurrency(balAtomic, 4);
      const balAtomic = 0;
      const bal = "0.0";
      const accInfo = await getAccountInfo(addr);
      let assets = [];
      let next = undefined;
      do {
        const res = await getAccountAssets(addr, { next });
        assets.push(res.assets);
        next = res["next-token"];
      } while (next);
      /*
      for (let i in accInfo.assets) {
        let asset = accInfo.assets[i]
        let assetId = asset['asset-id']
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`
        let storedAsset = localStorage.getItem(key)
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo))
          }
        }
      }
      */
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo,
          assets: assets.flat(),
        },
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleChange = async ({ target }) => {
    let { name, value } = target;
    console.log({ name, value });
    switch (name) {
      case "ASSETID":
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value;
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId);
          DECIMALS = decimals;
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        });
        break;
      case "INFO":
      case "PASS":
      case "AMT":
      case "TYPE":
      case "METHOD":
      case "SKIPCHECK":
        value = parseInt(value);
        break;
      default:
        break;
    }
    setQuery({ ...query, [name]: value });
  };

  const handleRefresh = async () => {
    console.log("Refreshing ...");
    try {
      setRefreshing(true);
      const addr = stdlib.formatAddress(state.acc.networkAccount.addr);
      localStorage.setItem("addr", state.acc.networkAccount.addr);
      const balAtomic = await stdlib.balanceOf(state.acc);
      const bal = stdlib.formatCurrency(balAtomic, 4);
      const accInfo = await getAccountInfo(addr);
      for (let i in accInfo.assets) {
        let asset = accInfo.assets[i];
        let assetId = asset["asset-id"];
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`;
        let storedAsset = localStorage.getItem(key);
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data;
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo));
          }
        }
      }
      setRefreshing(false);
      setState({
        ...state,
        acc: {
          ...state.acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleTest = async () => {
    let addrs = splitAddrs(query.ADDRS);

    let assetId = parseInt(query.ASSETID.id);
    let { decimals, unitname } = await getAsset(assetId);
    let candidates = [];
    for (let i in addrs) {
      console.log(`Progress: ${i / addrs.length}%`);
      let addr = addrs[i];
      if (query.SKIPCHECK === 0) {
        //let accInfo = await getAccountInfo(addr)
        candidates.push({
          addr,
          optin: null, // accInfo.assets.some(el => el['asset-id'] === assetId)
        });
      } else {
        let accInfo = await getAccountInfo(addr);
        candidates.push({
          addr,
          optin: accInfo.assets.some((el) => el["asset-id"] === assetId),
        });
      }
    }
    setState({
      ...state,
      assetId,
      decimals,
      unitname,
      success: false,
      tested: true,
      addrs: candidates,
    });
  };

  const handleTableInit = async () => {
    let addrs = splitAddrs(query.ADDRS);
    let candidates = [];
    for (let i in addrs) {
      let addr = addrs[i];
      candidates.push({
        addr,
        optin: true,
      });
    }
    setState({
      ...state,
      assetId: 0,
      decimals: 6,
      unitname: "ALGO",
      success: false,
      tested: true,
      addrs: candidates,
    });
  };

  const handleExecute = async () => {
    try {
      if (
        (localStorage.getItem("walletFallback") || "Mnemonic") === "Mnemonic"
      ) {
        let yesNo = window.prompt(
          "Are you sure you want to do this (type yes and click ok button)"
        );
        if (yesNo !== "yes") return;
      }
      let amount =
        query.TYPE === 0
          ? stdlib.parseCurrency(query.AMOUNT)
          : getAmtForContract(query.AMOUNT, state.decimals);
      let addrs = [];
      for (let i in state.addrs) {
        console.log({ i });
        let el = state.addrs[i];
        let success;
        let message;
        try {
          //if (query.SKIPCHECK === 0 && (query.TYPE === 0 || isOptin(el))) {
          //await new Promise(resolve => setTimeout(resolve, 100))
          if (query.TYPE === 0) {
            await stdlib.transfer(state.acc, el.addr, amount); // Algo transfer succeeds or fails
          } else {
            await stdlib.transfer(state.acc, el.addr, amount, state.assetId); // ASA transfer succeeds or fails
          }
          state.addrs[i].success = true;
          setState({ ...state, addrs: state.addrs });
          success = true;
          message = "";
          //} else {
          //  success = false
          //  message = "Skipped: missing asset optin"
          //  state.addrs[i].success = false
          //  state.addrs[i].message = message
          //  setState({ ...state, addrs: state.addrs })
          //}
        } catch (e) {
          console.log(e);
          message = "Transfer failed"; // <-- it failed
          success = false;
          state.addrs[i].success = false;
          state.addrs[i].message = message;
          setState({ ...state, addrs: state.addrs });
        }
        addrs.push({
          ...el,
          success,
          message,
        });
      }
      setState({
        ...state,
        addrs,
        success: true,
        confetti: true,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleRetry = async () => {
    try {
      let yesNo = window.prompt(
        "Are you sure you want to do this (type yes and click ok button)"
      );
      if (yesNo !== "yes") return;
      let amount =
        query.TYPE === 0
          ? stdlib.parseCurrency(query.AMOUNT)
          : getAmtForContract(query.AMOUNT, state.decimals);
      let addrs = [];
      for (let i in state.addrs) {
        console.log({ i });
        let el = state.addrs[i];
        if (el.success) {
          addrs.push(el);
          continue;
        }
        let success;
        let message;
        try {
          //if (query.TYPE === 0 || isOptin(el)) {
          //await new Promise(resolve => setTimeout(resolve, 100))
          if (query.TYPE === 0) {
            await stdlib.transfer(state.acc, el.addr, amount); // Algo transfer succeeds or fails
          } else {
            await stdlib.transfer(state.acc, el.addr, amount, state.assetId); // ASA transfer succeeds or fails
          }
          state.addrs[i].success = true;
          setState({ ...state, addrs: state.addrs });
          success = true;
          message = "";
          //} else {
          //  success = false
          //  message = "Skipped: missing asset optin"
          //}
        } catch (e) {
          console.log(e);
          message = "Transfer failed"; // <-- it failed
          success = false;
        }
        addrs.push({
          ...el,
          success,
          message,
        });
      }
      setState({
        ...state,
        addrs,
        success: true,
        confetti: true,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleDisconnect = () => setState(initialState);

  const handleProviderSelect = (providerEnv) => {
    localStorage.setItem("providerEnv", providerEnv);
    window.location.reload();
  };

  const handleSupport = async () => {
    let addr = addrEnv;
    let inputAmt = window.prompt("Enter ALGO amount to send");
    if (!inputAmt) return;
    let amt = stdlib.parseCurrency(inputAmt);
    stdlib
      .transfer(state.acc, addr, amt)
      .then(() => alert("ALGO recieved! Thank you!"));
  };

  const handleFetch = async () => {
    let assetId = parseInt(query.ASSETID.id);
    let { decimals, unitname } = await getAsset(assetId);
    let addrs = (await getAllAssetBalances(assetId)).map(
      ({ address: addr }) => ({ addr, optin: true })
    );
    setState({
      ...state,
      assetId,
      decimals,
      unitname,
      addrs,
      success: false,
      tested: true,
    });
  };

  const { width, height } = useWindowSize();

  /* autocomplete */
  const eventHandler = async (e) => {
    setLoading(true);
    let search = e.target.value;
    if (!search) return;
    let ids = splitAddrs(search);
    console.log({ ids });

    const list = [];
    console.log({ list });
    for (let i in ids) {
      let id = parseInt(ids[i]);
      let assetInfo = await getAsset(id);
      console.log({ id, assetInfo });
      let holders;
      let next;
      do {
        let params = {
          limit: 1000,
          exclude: "all",
        };
        if (requireBalance) {
          params["currency-greater-than"] = 0;
        }
        if (next) {
          params.next = next;
        }
        let holders = await getHolders(id, params);
        for (let j in holders.accounts) {
          const holder = holders.accounts[j];
          const res = await getAccountAssets(holder.address);
          console.log({ holder, assets: res });
          list.push({
            ...holder,
            asset: {
              ...assetInfo,
              ...assetInfo.params,
              ...res.assets.filter((el) => el["asset-id"] === id)[0],
            },
            amount: res.assets.filter((el) => el["asset-id"] === id)[0].amount,
          });
        }
        /*
        list.push(
          holders.accounts
            .map((el) => {
              //const { assets } = await getAccountAssets(el.address);
              //console.log({ assets });
              //console.log({ id });
              //const fAssets = assets.filter(
              //  (el) => el.amount > 0 && el["asset-id"] === id
              //);
              //console.log({ fAssets });
              //if (fAssets.length !== 1) {
              //  return null;
              //}
              //const asset = fAssets.pop();
              //console.log({ asset });
              return {
                ...el,
                asset: {
                  ...assetInfo,
                  ...assetInfo.params,
                  //...asset,
                  "opted-in-at-round": 1
                },
                //amount: asset.amount,
                amount: 1
              };
            })
            .filter((el) => !!el && !!el["sig-type"]) // filter out lsigs
        );
        */
        next = holders["next-token"];
      } while (next);
    }

    console.log({ list });
    setLoading(false);
    const bList = list
      .flatMap((el) => el)
      //.sort((a, b) => b.amount - a.amount)
      .sort(
        (a, b) => a.asset["opted-in-at-round"] - b.asset["opted-in-at-round"]
      );
    console.log(bList);
    setList(bList);
  };

  const debouncedEventHandler = useMemo(
    () => debounce(eventHandler, 300),
    [requireBalance]
  );

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleImport = async (event) => {
    let { files } = event.target;
    let lines = String(await files[0].text()).split("\n");
    let headers = lines[0];
    let data = lines.slice(1);
    console.log({ data });
    let tmp = [];
    data.forEach((datum) => {
      let csv = datum.split(",");
      console.log(csv);
      let obj = {};
      headers.split(",").forEach((header, index) => {
        obj[header.trim()] = csv[index].trim();
      });
      tmp.push(obj);
    });
    console.log({ tmp });
  };

  const handleExport = () => {
    const getFileName = () => "export.csv";
    const fileDownload = require("js-file-download");
    fileDownload(
      [
        [
          "Address",
          "Amount",
          "Asset Unit Name",
          "Asset Name",
          "Asset ID",
          "Optin Round",
        ],
        ...list.map(({ address, asset, amount }) => [
          address,
          amount,
          asset["name"],
          asset["unit-name"],
          asset["index"],
          asset["opted-in-at-round"],
        ]),
      ].join("\r\n"),
      getFileName(),
      "text/csv",
      new Uint8Array([0xef, 0xbb, 0xbf])
    );
  };

  return (
    <Container className="pb-5">
      {state.confetti && <Confetti width={width} height={height} />}
      <Modal
        open={!close}
        onClose={toggleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Settings modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      <Row className="mt-5">
        <Col>
          <h1
            style={{
              fill: "pink",
              color: "black",
              background: "white",
            }}
            className="text-center"
          >
            <img
              style={{
                height: "44px",
                verticalAlign: "baseline",
                background:
                  providerEnv === "MainNet" ? "deepskyblue" : "darkorange",
                borderRadius: "50px",
              }}
              src={drop}
            />
            <span
              style={{
                color: providerEnv === "MainNet" ? "red" : "green",
              }}
            >
              Algo
            </span>
            Drop
            <span
              style={{
                fontSize: "12px",
              }}
            >
              v0.0.5
            </span>
          </h1>
        </Col>
        <Col className="text-center" xs={12}>
          This runs on
          <img
            style={{
              height: "30px",
            }}
            src={logo}
            alt="Algorand log"
          />
          <ProviderSelector />
        </Col>
      </Row>
      <Row className="mt-5">
        <h2>Mint</h2>
        <p>
          Takes json object as mint parameters.
          <br />
          ex) <br />
          Input: JSON<br />
          Output: void (mint arch69 nft)<br />
        </p>
        <Col className="mt-3" xs={12}>
          <TextareaAutosize
            fullWidth
            label="Name"
            id="fullWidth"
            onChange={({ target }) =>
              setQuery({ ...query, NAME: target.value })
            }
          />
        </Col>
        <Col className="mt-3" xs={12}>
          <Button onClick={handleConnect}>Connect</Button>
        </Col>
        <Col className="mt-3" xs={12}>
          <Button onClick={handleMint}>Mint</Button>
        </Col>
      </Row>
      {false && (
        <>
          <input type="file" id="input" onChange={handleImport} />
          <Button onClick={handleImport}>Import from CSV</Button>
        </>
      )}
      {!loading ? (
        list.length > 0 && (
          <>
            <div className="mt-5">
              <Button
                onClick={() => copy(list.map((el) => el.address).join("\r"))}
              >
                Copy
              </Button>
              <Button onClick={handleExport}>Export to CSV</Button>
            </div>
            <Table className="mt-5" bordered hover size="sm">
              <thead>
                <tr>
                  <th colSpan={4}>Address</th>
                </tr>
                <tr>
                  <th>Amount</th>
                  <th>Unit Name</th>
                  <th>Asset ID</th>
                  <th>Optin Round</th>
                </tr>
              </thead>
              <tbody>
                {list.map(({ address, amount, asset }) => (
                  <>
                    <tr key={`${asset.id}-${address}-addr`}>
                      <td colSpan={4} onClick={() => copy(address)}>
                        {address}&nbsp;
                        <ContentCopyIcon />
                      </td>
                    </tr>
                    <tr key={`${asset.id}-${address}-info`}>
                      <td>{amount}</td>
                      <td>{asset["unit-name"]}</td>
                      <td>{asset.index}</td>
                      <td>{asset["opted-in-at-round"]}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
          </>
        )
      ) : (
        <div className="mt-5 text-center">
          <CircularProgress size={120} />
        </div>
      )}
    </Container>
  );
}

export default withMenu(Optin);
