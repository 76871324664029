import { default as MAC } from "@randlabs/myalgo-connect";
import algosdk from "algosdk";
import { useEffect, useState } from "react";
import Icon from "react-crypto-icons";
import { loadStdlib } from "@reach-sh/stdlib";
import MyAlgoConnect from "@reach-sh/stdlib/ALGO_MyAlgoConnect";
import WalletConnect from "@reach-sh/stdlib/ALGO_WalletConnect";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./App.css";
import {
  getAmtForContract as getAmtForContractHelper,
  getAccountInfo,
  getAsset,
  formatMnemonic,
  getStoredAsset,
  getAllAssetBalances,
  formatCompactAddress,
  splitAddrs,
  getAccountAssets,
} from "./functions";
import { ButtonGroup, Dropdown, Spinner, Table } from "react-bootstrap";
import logo from "./algorand_full_logo_black.svg";
import drop from "./drop.svg";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import { Autocomplete, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import useCopy from "./hooks/useCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import ClearIcon from "@mui/icons-material/Clear";
import { useHistory, useNavigate } from "react-router-dom";
import { withMenu } from "./hoc/withMenu";
import ProviderSelector from "./components/ProviderSelector";

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK, REACT_APP_ADDR } =
  process.env;

const networkEnv =
  REACT_APP_NETWORK || localStorage.getItem("networkEnv") || "ALGO";
const providerType =
  REACT_APP_NETWORK_PROVIDER ||
  localStorage.getItem("providerEnv") ||
  "MainNet";

let providerEnv;
switch (providerType) {
  case "MainNet":
  case "MainNet-RandLabs":
  case "MainNet-AlgoNode":
    providerEnv = {
      ALGO_TOKEN: "",
      ALGO_SERVER: "https://mainnet-api.algonode.cloud",
      ALGO_PORT: "",
      ALGO_NODE_WRITE_ONLY: "no",

      ALGO_INDEXER_TOKEN: "",
      ALGO_INDEXER_SERVER: "https://mainnet-idx.algonode.cloud",
      ALGO_INDEXER_PORT: "",
    };
    break;
  case "TestNet":
  case "TestNet-RandLabs":
  case "TestNet-AlgoNode":
    providerEnv = {
      ALGO_TOKEN: "",
      ALGO_SERVER: "https://testnet-api.algonode.cloud",
      ALGO_PORT: "",
      ALGO_NODE_WRITE_ONLY: "no",

      ALGO_INDEXER_TOKEN: "",
      ALGO_INDEXER_SERVER: "https://testnet-idx.algonode.cloud",
      ALGO_INDEXER_PORT: "",
    };
    break;
}

const addrEnv = REACT_APP_ADDR;

const stdlib = loadStdlib(networkEnv);

console.log(localStorage.getItem("walletFallback"));
if (networkEnv === "ALGO") {
  if (!localStorage.getItem("walletFallback")) {
    localStorage.setItem("walletFallback", "Mnemonic");
  }
  if (!localStorage.getItem("settingsWalletFallback")) {
    localStorage.setItem("settingsWalletFallback", "MyAlgoConnect");
  }
  if (localStorage.getItem("walletFallback") === "MyAlgoConnect") {
    stdlib.setWalletFallback(
      stdlib.walletFallback({
        providerEnv,
        MyAlgoConnect,
      })
    );
  } else if (localStorage.getItem("walletFallback") === "WalletConnect") {
    stdlib.setWalletFallback(
      stdlib.walletFallback({
        providerEnv,
        WalletConnect,
      })
    );
  } else {
    stdlib.setWalletFallback(
      stdlib.walletFallback({
        providerEnv,
      })
    );
  }
}

const getAmtForContract = getAmtForContractHelper(stdlib);

function App() {
  const copy = useCopy();
  const navigate = useNavigate();
  const initialState = {
    acc: null,
    addrs: [],
    success: false,
    confetti: false,
  };
  const [state, setState] = useState(initialState);
  const [refreshing, setRefreshing] = useState(false);
  const [query, setQuery] = useState({
    PLAN: 0, // Default
    TYPE: 1, // ASA
    METHOD: 1, // LIST
    SKIPCHECK: 1, // SKIP OPTIN CHECK
  });
  // code to build up stored asset info in background
  // Chrome only
  /*
  useEffect(() => {
    if (!state.acc) return
    (async () => {
      let { assets } = state.acc
      for (let i in assets) {
        let asset = assets[i]
        let assetId = asset['asset-id']
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`
        let storedAsset = localStorage.getItem(key)
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo))
          }
        }
      }
    })()
  }, [state.acc])
  */
  // show confetti
  useEffect(() => {
    if (!state.confetti) return;
    let timeout = setTimeout(() => {
      setState({ ...state, confetti: false });
    }, 30000);
  }, [state.confetti]);

  const isOptin = ({ optin }) => optin === true;

  const transferALGO = async (fromAddr, toAddrs, amount) => {
    console.log({
      fromAddr,
      toAddrs,
      amount,
    });
    const myAlgoWallet = new MAC();
    const algodClient = new algosdk.Algodv2(
      "",
      providerType === "MainNet"
        ? "https://node.mainnetnet.algoexplorerapi.io"
        : "https://node.testnet.algoexplorerapi.io",
      ""
    );
    const params = await algodClient.getTransactionParams().do();
    const enc = new TextEncoder(); // always utf-8
    let txns = toAddrs.map((el) =>
      algosdk.makePaymentTxnWithSuggestedParams(
        fromAddr,
        el,
        amount,
        undefined,
        undefined,
        params
      )
    );
    let txgroup = algosdk.assignGroupID(txns, fromAddr);
    let stx = await myAlgoWallet.signTransaction(
      txgroup.map((el) => el.toByte())
    );
    let res = await algodClient
      .sendRawTransaction(stx.map((el) => el.blob))
      .do();
    console.log(res);
    // "Unable to broadcast transaction. Please try again"
    // "Unable to sign transaction. Please try again"
  };

  const handleChange = async ({ target }) => {
    let { name, value } = target;
    console.log({ name, value });
    switch (name) {
      case "ASSETID":
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value;
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId);
          DECIMALS = decimals;
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        });
        break;
      case "INFO":
      case "PASS":
      case "PLAN":
      case "AMT":
      case "TYPE":
      case "METHOD":
      case "SKIPCHECK":
        value = parseInt(value);
        break;
      default:
        break;
    }
    setQuery({ ...query, [name]: value });
  };

  const handleConnect = async () => {
    try {
      console.log("Connecting ...");

      let acc;
      switch (localStorage.getItem("walletFallback") || "MyAlgoConnect") {
        case "MyAlgoConnect":
        case "WalletConnect":
          acc = await stdlib.getDefaultAccount();
          break;
        case "Mnemonic":
        default:
          let mn = window.prompt("Enter mnemonic phrase");
          acc = await stdlib.newAccountFromMnemonic(formatMnemonic(mn));
      }
      console.log(acc);

      const addr = stdlib.formatAddress(acc.networkAccount.addr);
      localStorage.setItem("addr", acc.networkAccount.addr);

      //const balAtomic = await stdlib.balanceOf(acc);
      //const bal = stdlib.formatCurrency(balAtomic, 4);
      const balAtomic = 0;
      const bal = "0.0";

      const accInfo = await getAccountInfo(addr);

      let assets = [];
      let next = undefined;
      do {
        const res = await getAccountAssets(addr, { next });
        assets.push(res.assets);
        next = res["next-token"];
      } while(next);

      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleRefresh = async () => {
    console.log("Refreshing ...");
    try {
      setRefreshing(true);
      const addr = stdlib.formatAddress(state.acc.networkAccount.addr);
      localStorage.setItem("addr", state.acc.networkAccount.addr);
      const balAtomic = await stdlib.balanceOf(state.acc);
      const bal = stdlib.formatCurrency(balAtomic, 4);
      const accInfo = await getAccountInfo(addr);
      for (let i in accInfo.assets) {
        let asset = accInfo.assets[i];
        let assetId = asset["asset-id"];
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`;
        let storedAsset = localStorage.getItem(key);
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data;
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo));
          }
        }
      }
      setRefreshing(false);
      setState({
        ...state,
        acc: {
          ...state.acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  // for import
  const handleTest2 = async () => {
    let { addrs } = state;
    let candidates = [];
    for (let i in addrs) {
      console.log(`Progress: ${i / addrs.length}%`);
      let it = addrs[i];
      let { addr, drop: asset } = it;
      let { id } = asset;
      console.log(addr, id);
      let accInfo = await getAccountInfo(addr);
      console.log(accInfo);
      candidates.push({
        ...it,
        optin: accInfo.assets.some((el) => el["asset-id"] === parseInt(id)),
      });
    }
    console.log({ candidates });
    setState({
      ...state,
      success: false,
      tested: true,
      addrs: candidates,
    });
  };

  const handleTest = async () => {
    let addrs = splitAddrs(query.ADDRS);

    let assetId = parseInt(query.ASSETID.id);
    let { decimals, unitname } = await getAsset(assetId);
    let candidates = [];
    for (let i in addrs) {
      console.log(`Progress: ${i / addrs.length}%`);
      let addr = addrs[i];
      if (query.SKIPCHECK === 0) {
        //let accInfo = await getAccountInfo(addr)
        candidates.push({
          addr,
          optin: null, // accInfo.assets.some(el => el['asset-id'] === assetId)
        });
      } else {
        let accInfo = await getAccountInfo(addr);
        candidates.push({
          addr,
          optin: accInfo.assets.some((el) => el["asset-id"] === assetId),
        });
      }
    }
    setState({
      ...state,
      assetId,
      decimals,
      unitname,
      success: false,
      tested: true,
      addrs: candidates,
    });
  };

  function spliceIntoChunks(arr, chunkSize) {
    const res = [];
    while (arr.length > 0) {
      const chunk = arr.splice(0, chunkSize);
      res.push(chunk);
    }
    return res;
  }

  const handleTableInit = async () => {
    let addrs = splitAddrs(query.ADDRS);
    let candidates = [];
    for (let i in addrs) {
      let addr = addrs[i];
      candidates.push({
        addr,
        optin: true,
      });
    }
    setState({
      ...state,
      assetId: 0,
      decimals: 6,
      unitname: "ALGO",
      success: false,
      tested: true,
      addrs: candidates,
      //addrs: spliceIntoChunks(candidates, 16),
    });
  };

  const handleExecuteImport = async () => {
    try {
      if (
        (localStorage.getItem("walletFallback") || "Mnemonic") === "Mnemonic"
      ) {
        let yesNo = window.prompt(
          "Are you sure you want to do this (type yes and click ok button)"
        );
        if (yesNo !== "yes") return;
      }
      let amount; // = query.TYPE === 0 ? stdlib.parseCurrency(query.AMOUNT) : getAmtForContract(query.AMOUNT, state.decimals)
      let addrs = [];
      for (let i = 0; i < 16; i += 16) {
        //for (let i in state.addrs) {
        if (query.PLAN === 2) {
          // TODO use asset info lookup
          let asset = await getAsset(state.addrs[i].drop.id);
          console.log(asset);
          amount =
            state.addrs[i].drop.id === "0"
              ? stdlib.parseCurrency(state.addrs[i].drop.amount)
              : getAmtForContract(state.addrs[i].drop.amount, 1);
          //: getAmtForContract(state.addrs[i].drop.amount, asset.decimals)
          console.log(amount);
        }
        console.log({ i });
        let el = state.addrs[i];
        let success;
        let message;
        try {
          //if (query.SKIPCHECK === 0 && (query.TYPE === 0 || isOptin(el))) {
          //await new Promise(resolve => setTimeout(resolve, 100))
          if (state.addrs[i].drop.id === "0") {
            transferALGO(
              state.acc.networkAccount.addr,
              state.addrs.slice(i, i + 16),
              amount
            );
            //await stdlib.transfer(state.acc, el.addr, amount); // Algo transfer succeeds or fails
          } else {
            await stdlib.transfer(
              state.acc,
              el.addr,
              amount,
              state.addrs[i].drop.id
            ); // ASA transfer succeeds or fails
          }
          state.addrs[i].success = true;
          //setState({ ...state, addrs: state.addrs });
          success = true;
          message = "";
          //} else {
          //  success = false
          //  message = "Skipped: missing asset optin"
          //  state.addrs[i].success = false
          //  state.addrs[i].message = message
          //  setState({ ...state, addrs: state.addrs })
          //}
        } catch (e) {
          console.log(e);
          message = "Transfer failed"; // <-- it failed
          success = false;
          state.addrs[i].success = false;
          state.addrs[i].message = message;
          setState({ ...state, addrs: state.addrs });
        }
        addrs.push({
          ...el,
          success,
          message,
        });
      }
      setState({
        ...state,
        addrs,
        success: true,
        confetti: true,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleExecute = async () => {
    try {
      if (
        (localStorage.getItem("walletFallback") || "Mnemonic") === "Mnemonic"
      ) {
        let yesNo = window.prompt(
          "Are you sure you want to do this (type yes and click ok button)"
        );
        if (yesNo !== "yes") return;
      }
      let amount =
        query.TYPE === 0
          ? stdlib.parseCurrency(query.AMOUNT)
          : getAmtForContract(query.AMOUNT, state.decimals);
      let addrs = [];
      console.log(state.addrs);
      for (let i in state.addrs) {
        if (query.PLAN === 2) {
          // TODO use asset info lookup
          let asset = await getAsset(state.addrs[i].drop.id);
          console.log(asset);
          amount =
            state.addrs[i].drop.amount === "0"
              ? stdlib.parseCurrency(state.addrs[i].drop.amount)
              : getAmtForContract(state.addrs[i].drop.amount, asset.decimals);
          console.log(amount);
        }
        console.log({ i });
        let el = state.addrs[i];
        let success;
        let message;
        try {
          //if (query.SKIPCHECK === 0 && (query.TYPE === 0 || isOptin(el))) {
          //await new Promise(resolve => setTimeout(resolve, 100))
          if (query.TYPE === 0) {
            // TRANSFER ALGO
            // - atomic transfer
            //await transferALGO(
            //  state.addr,
            //  el.map((el) => el.addr),
            //  parseInt(query.AMOUNT) * 1000000
            //);
            // - reach stdlib
            await stdlib.transfer(state.acc, el.addr, amount); // Algo transfer succeeds or fails
          } else {
            await stdlib.transfer(state.acc, el.addr, amount, state.assetId); // ASA transfer succeeds or fails
          }
          state.addrs[i].success = true;
          setState({ ...state, addrs: state.addrs });
          success = true;
          message = "";
          //} else {
          //  success = false
          //  message = "Skipped: missing asset optin"
          //  state.addrs[i].success = false
          //  state.addrs[i].message = message
          //  setState({ ...state, addrs: state.addrs })
          //}
        } catch (e) {
          console.log(e);
          message = "Transfer failed"; // <-- it failed
          success = false;
          state.addrs[i].success = false;
          state.addrs[i].message = message;
          setState({ ...state, addrs: state.addrs });
        }
        addrs.push({
          ...el,
          success,
          message,
        });
      }
      setState({
        ...state,
        addrs,
        success: true,
        confetti: true,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleRetry = async () => {
    try {
      let yesNo = window.prompt(
        "Are you sure you want to do this (type yes and click ok button)"
      );
      if (yesNo !== "yes") return;
      let amount =
        query.TYPE === 0
          ? stdlib.parseCurrency(query.AMOUNT)
          : getAmtForContract(query.AMOUNT, state.decimals);
      let addrs = [];
      for (let i in state.addrs) {
        console.log({ i });
        let el = state.addrs[i];
        if (el.success) {
          addrs.push(el);
          continue;
        }
        let success;
        let message;
        try {
          //if (query.TYPE === 0 || isOptin(el)) {
          //await new Promise(resolve => setTimeout(resolve, 100))
          if (query.TYPE === 0) {
            await stdlib.transfer(state.acc, el.addr, amount); // Algo transfer succeeds or fails
          } else {
            await stdlib.transfer(state.acc, el.addr, amount, state.assetId); // ASA transfer succeeds or fails
          }
          state.addrs[i].success = true;
          setState({ ...state, addrs: state.addrs });
          success = true;
          message = "";
          //} else {
          //  success = false
          //  message = "Skipped: missing asset optin"
          //}
        } catch (e) {
          console.log(e);
          message = "Transfer failed"; // <-- it failed
          success = false;
        }
        addrs.push({
          ...el,
          success,
          message,
        });
      }
      setState({
        ...state,
        addrs,
        success: true,
        confetti: true,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleDisconnect = () => setState(initialState);

  const handleProviderSelect = (providerEnv) => {
    localStorage.setItem("providerEnv", providerEnv);
    window.location.reload();
  };

  const handleSupport = async () => {
    let addr = addrEnv;
    let inputAmt = window.prompt("Enter ALGO amount to send");
    if (!inputAmt) return;
    let amt = stdlib.parseCurrency(inputAmt);
    stdlib
      .transfer(state.acc, addr, amt)
      .then(() => alert("ALGO recieved! Thank you!"));
  };

  const handleFetch = async () => {
    let assetId = parseInt(query.ASSETID.id);
    let { decimals, unitname } = await getAsset(assetId);
    let addrs = (await getAllAssetBalances(assetId)).map(
      ({ address: addr }) => ({ addr, optin: true })
    );
    setState({
      ...state,
      assetId,
      decimals,
      unitname,
      addrs,
      success: false,
      tested: true,
    });
  };

  const { width, height } = useWindowSize();

  const handleImport = async (event) => {
    let { files } = event.target;
    let lines = String(await files[0].text()).split("\n");
    let headers = lines[0];
    let data = lines.slice(1);
    console.log({ data });
    let tmp = [];
    data.forEach((datum) => {
      let csv = datum.split(",");
      console.log(csv);
      let obj = {};
      headers.split(",").forEach((header, index) => {
        obj[header.trim()] = csv[index].trim();
      });
      tmp.push(obj);
    });
    console.log({ tmp });
    setState({
      ...state,
      addrs: tmp.map((el) => ({
        addr: el["Address"],
        amount: el["Amount"],
        assetname: el["Asset Name"],
        drop: {
          amount: el["Drop Amount"],
          unitname: el["Drop Unit Name"],
          id: el["Drop Asset ID"],
        },
      })),
    });
  };

  return (
    <Container className="pb-5">
      {state.confetti && <Confetti width={width} height={height} />}
      <Row className="mt-5">
        <Col>
          <h1
            style={{
              fill: "pink",
              color: "black",
              background: "white",
            }}
            className="text-center"
          >
            <img
              style={{
                height: "44px",
                verticalAlign: "baseline",
                background:
                  providerEnv === "MainNet" ? "deepskyblue" : "darkorange",
                borderRadius: "50px",
              }}
              src={drop}
            />
            <span
              style={{
                color: providerEnv === "MainNet" ? "red" : "green",
              }}
            >
              Algo
            </span>
            Drop
            <span
              style={{
                fontSize: "12px",
              }}
            >
              v0.0.5
            </span>
          </h1>
        </Col>
        <Col className="text-center" xs={12}>
          This runs on
          <img
            style={{
              height: "30px",
            }}
            src={logo}
            alt="Algorand log"
          />
          <ProviderSelector />
        </Col>
      </Row>
      <Row>
        <Col className="mt-5" xs={12}>
          <h2>Drop</h2>
          <p>
            Takes amount and list of wallet address and sends amount to list of
            wallets.
            <br />
            ex) <br />
            Input: 1 ALGO <br />
            ZZAF5ARA4MEC5PVDOP64JM5O5MQST63Q2KOY2FLYFLXXD3PFSNJJBYAFZM <br />
            Output: Sends 1 ALGO to account
            <br />
            Supports sending ALGO or ASA to multiple accounts.
          </p>
        </Col>
      </Row>
      {!state.acc && (
        <Row className="mt-5 role role-participant">
          <Col>
            <ButtonGroup>
              <Dropdown as={ButtonGroup}>
                <Button onClick={handleConnect}>Connect</Button>
                <Dropdown.Toggle split id="dropdown-basic" />
                <Dropdown.Menu>
                  {false && (
                    <Dropdown.Item
                      onClick={() => {
                        localStorage.setItem("walletFallback", "Mnemonic");
                        window.location.reload();
                      }}
                    >
                      {localStorage.getItem("walletFallback") ===
                        "Mnemonic" && <CheckIcon />}
                      Mnemonic
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      localStorage.setItem("walletFallback", "MyAlgoConnect");
                      window.location.reload();
                    }}
                  >
                    {localStorage.getItem("walletFallback") ===
                      "MyAlgoConnect" && <CheckIcon />}
                    My Algo Connect
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      localStorage.setItem("walletFallback", "WalletConnect");
                      window.location.reload();
                    }}
                  >
                    {localStorage.getItem("walletFallback") ===
                      "WalletConnect" && <CheckIcon />}
                    Wallet Connect
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      )}
      {state.acc && (
        <Row className="mt-5">
          <Col className="mt-5" xs={12}>
            Connected as{` `}
            <Dropdown as={ButtonGroup}>
              <Button
                style={{ fill: "white" }}
                variant="dark"
                onClick={() => copy(state.addr)}
              >
                {formatCompactAddress(state.addr)} |{" "}
                <Icon
                  style={{ verticalAlign: "baseline" }}
                  size={11}
                  name="algo"
                />{" "}
                {state.bal}
              </Button>
              <Dropdown.Toggle variant="dark" split id="dropdown-basic" />
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleDisconnect}>
                  Disconnect
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {` `}
            <span>
              {!refreshing ? (
                <RefreshIcon
                  style={{ verticalAlign: "sub" }}
                  fontSize="small"
                  onClick={handleRefresh}
                />
              ) : (
                <Spinner animation="border" variant="secondary" size="sm" />
              )}
            </span>
          </Col>

          {false && (
            <>
              <Col className="mt-5" xs={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Drop mode</Form.Label>
                  <Form.Check
                    name="PLAN"
                    type="radio"
                    id={`default-radio`}
                    label="Default"
                    onChange={handleChange}
                    defaultChecked={true}
                    value={0}
                  />
                  {false && (
                    <>
                      <Form.Check
                        className="d-inline-block"
                        name="PLAN"
                        type="radio"
                        id={`default-radio`}
                        label="List"
                        onChange={handleChange}
                        value={1}
                        dispabled
                      />{" "}
                      <span
                        onClick={() => {
                          navigate("/asset/1");
                        }}
                      >
                        Go to list
                      </span>
                    </>
                  )}
                  <Form.Check
                    className="d-inline-block"
                    name="PLAN"
                    type="radio"
                    id={`default-radio`}
                    label="Import"
                    onChange={handleChange}
                    value={2}
                    dispabled
                  />
                </Form.Group>
              </Col>
              <hr />
            </>
          )}
          {false && query.PLAN === 2 && (
            <>
              <input type="file" id="input" onChange={handleImport} />
              <button onClick={handleTest2}>Test</button>
              {/* need to test */}
            </>
          )}
          {query.PLAN === 0 && (
            <>
              <Col className="mt-5" xs={12}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Drop type</Form.Label>
                  <Form.Check
                    name="TYPE"
                    type="radio"
                    id={`default-radio`}
                    label="ALGO"
                    onChange={handleChange}
                    value={0}
                  />
                  <Form.Check
                    name="TYPE"
                    type="radio"
                    id={`default-radio`}
                    label="ASA"
                    defaultChecked={true}
                    onChange={handleChange}
                    value={1}
                  />
                </Form.Group>
                {false && query.TYPE === 1 && (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Asset id</Form.Label>
                    <Form.Control
                      name="ASSETID"
                      onChange={(e) =>
                        setQuery({ ...query, ASSETID: { id: e.target.value } })
                      }
                      type="number"
                      placeholder="1234567890"
                    />
                  </Form.Group>
                )}
                {state.acc && query.TYPE === 1 && (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Asset id</Form.Label>
                    <Autocomplete
                      name="ASSETID"
                      onChange={(event, newValue) =>
                        handleChange({
                          target: { name: "ASSETID", value: newValue },
                        })
                      }
                      options={state.acc?.assets
                        ?.filter(({ amount }) => amount > 0)
                        .map((el) =>
                          (({
                            id,
                            assetname,
                            unitname,
                            decimals,
                            creator,
                          }) => ({
                            label: id
                              ? `${id} : ${assetname} (${unitname}) : ${el.amount}`
                              : `${el["asset-id"]} : ${el.amount}`,
                            id: el["asset-id"],
                            decimals,
                            creator,
                          }))(getStoredAsset(el["asset-id"]) || {})
                        )}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                    />
                  </Form.Group>
                )}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Amount to distribute</Form.Label>
                  <Form.Control
                    name="AMOUNT"
                    onChange={handleChange}
                    type="number"
                    placeholder="10"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Recipients</Form.Label>
                  {query.TYPE === 1 && (
                    <Form.Check
                      name="METHOD"
                      type="radio"
                      id={`default-radio`}
                      label="All Holders"
                      onChange={handleChange}
                      value={0}
                    />
                  )}
                  <Form.Check
                    name="METHOD"
                    type="radio"
                    id={`default-radio`}
                    label="List of Addresses"
                    defaultChecked={true}
                    onChange={handleChange}
                    value={1}
                  />
                </Form.Group>
                {query.TYPE === 1 && (
                  <>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Skip optin precheck</Form.Label>
                      <Form.Check
                        name="SKIPCHECK"
                        type="radio"
                        id={`default-radio`}
                        label="Yes"
                        onChange={handleChange}
                        value={0}
                      />
                      <Form.Check
                        name="SKIPCHECK"
                        type="radio"
                        id={`default-radio`}
                        label="No"
                        onChange={handleChange}
                        defaultChecked={true}
                        value={1}
                      />
                    </Form.Group>
                  </>
                )}
                {query.METHOD === 0 && (
                  <Button onClick={handleFetch}>Fetch Holders</Button>
                )}
                {query.METHOD === 1 && (
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Wallet addresses</Form.Label>
                    <Form.Control
                      name="ADDRS"
                      onChange={handleChange}
                      as="textarea"
                      placeholder="List of wallet addresses"
                      rows={3}
                    />
                  </Form.Group>
                )}
              </Col>
              {query.METHOD === 1 && (
                <Col className="mt-5" xs={12}>
                  {query.TYPE === 1 ? (
                    <Button onClick={handleTest}>Test</Button>
                  ) : (
                    <Button onClick={handleTableInit}>Load Table</Button>
                  )}
                </Col>
              )}
            </>
          )}
          {false && query.PLAN === 0 && (
            <>
              <Button onClick={() => {}}>Test</Button>
            </>
          )}
          {state.addrs && (
            <Col className="mt-5" xs={12}>
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Address</th>
                    {query.PLAN === 2 && <th>Hold</th>}
                    {query.PLAN === 2 && <th>Drop</th>}
                    {query.PLAN === 1 && <th>Asset</th>}
                    {query.TYPE === 1 && <th>Optin</th>}
                    <th>Sent</th>
                  </tr>
                </thead>
                <tbody>
                  {state.addrs.map((el, i) => (
                    <>
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <Button variant="light" onClick={() => copy(el.addr)}>
                            {formatCompactAddress(el.addr)}
                          </Button>
                        </td>
                        {query.PLAN === 2 && (
                          <th>{`${el.assetname} ${el.amount}`}</th>
                        )}
                        {query.PLAN === 2 && (
                          <th>{`${el?.drop?.unitname ?? ""} ${
                            el?.drop?.amount ?? ""
                          }`}</th>
                        )}
                        {query.PLAN === 1 && <th>ALGO</th>}
                        {query.TYPE === 1 && (
                          <td>
                            {el.optin ? (
                              <span style={{ color: "green" }}>true </span>
                            ) : el.optin === null ? (
                              "-"
                            ) : (
                              <span style={{ color: "red" }}>false </span>
                            )}
                          </td>
                        )}
                        <td>
                          {!el.success ? (
                            "-"
                          ) : el.success ? (
                            <span style={{ color: "green" }}>true </span>
                          ) : (
                            <span style={{ color: "red" }}>false</span>
                          )}
                        </td>
                      </tr>
                      {el.success === false && (
                        <tr>
                          <td>&nbsp;</td>
                          <td colSpan={3}>{el.message}</td>
                        </tr>
                      )}
                    </>
                  ))}
                  <tr>
                    <td colSpan={6}>
                      <div>
                        Fee estimate:{" "}
                        {state.addrs.filter(isOptin).length * 0.001} ALGO Total
                        amount:{" "}
                        {(query.TYPE === 0
                          ? state.addrs.length
                          : state.addrs.filter(isOptin).length) *
                          query.AMOUNT || 0}{" "}
                        {query.TYPE === 0 ? "ALGO" : state.unitname}
                      </div>
                      {query.TYPE === 1 && !state.addrs.every(isOptin) && (
                        <div className="text-warning">
                          Estimate excluding addresses missing optins to be
                          skipped
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          )}
          {state.tested && (
            <>
              <Col className="mt-5" xs={12}></Col>
              <Col className="mt-5" xs={12}>
                <ButtonGroup>
                  <Button onClick={handleExecute}>Execute</Button>
                  <Button onClick={handleRetry}>Retry</Button>
                </ButtonGroup>
              </Col>
            </>
          )}
          {state.success && (
            <>
              <Col className="mt-5" xs={12}>
                <Button variant="success" onClick={handleSupport}>
                  Support dev?
                </Button>
              </Col>
            </>
          )}
        </Row>
      )}
    </Container>
  );
}

export default withMenu(App);
