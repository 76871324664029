import { useEffect, useMemo, useState } from "react";
import Icon from "react-crypto-icons";
import { loadStdlib } from "@reach-sh/stdlib";
import MyAlgoConnect from "@reach-sh/stdlib/ALGO_MyAlgoConnect";
import WalletConnect from "@reach-sh/stdlib/ALGO_WalletConnect";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./App.css";
import {
  getAmtForContract as getAmtForContractHelper,
  getAccountInfo,
  getAsset,
  formatMnemonic,
  getHolders,
  getAllAssetBalances,
  formatCompactAddress,
  splitAddrs,
  getRichList,
  searchAssets,
  searchV1,
  getMinBalance,
} from "./functions";
import { ButtonGroup, Dropdown, Spinner, Table } from "react-bootstrap";
import logo from "./algorand_full_logo_black.svg";
import drop from "./drop.svg";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Input,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import useCopy from "./hooks/useCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate, useParams } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCopy from "@mui/icons-material/ContentCopy";
import context from "react-bootstrap/esm/AccordionContext";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SettingsIcon from "@mui/icons-material/Settings";
import BoltIcon from "@mui/icons-material/Bolt";
import ClearIcon from "@mui/icons-material/Clear";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import debounce from "lodash.debounce";
import { withMenu } from "./hoc/withMenu";
import ProviderSelector from "./components/ProviderSelector";

const { REACT_APP_NETWORK_PROVIDER, REACT_APP_NETWORK, REACT_APP_ADDR } =
  process.env;

const networkEnv =
  REACT_APP_NETWORK || localStorage.getItem("networkEnv") || "ALGO";
const providerType =
  REACT_APP_NETWORK_PROVIDER ||
  localStorage.getItem("providerEnv") ||
  "MainNet";

let providerEnv;
switch (providerType) {
  case "MainNet":
  case "MainNet-RandLabs":
  case "MainNet-AlgoNode":
    providerEnv = {
      ALGO_TOKEN: "",
      ALGO_SERVER: "https://mainnet-api.algonode.cloud",
      ALGO_PORT: "",
      ALGO_NODE_WRITE_ONLY: "no",

      ALGO_INDEXER_TOKEN: "",
      ALGO_INDEXER_SERVER: "https://mainnet-idx.algonode.cloud",
      ALGO_INDEXER_PORT: "",
    };
    break;
  case "TestNet":
  case "TestNet-RandLabs":
  case "TestNet-AlgoNode":
    providerEnv = {
      ALGO_TOKEN: "",
      ALGO_SERVER: "https://testnet-api.algonode.cloud",
      ALGO_PORT: "",
      ALGO_NODE_WRITE_ONLY: "no",

      ALGO_INDEXER_TOKEN: "",
      ALGO_INDEXER_SERVER: "https://testnet-idx.algonode.cloud",
      ALGO_INDEXER_PORT: "",
    };
    break;
}

const addrEnv = REACT_APP_ADDR;

const stdlib = loadStdlib(networkEnv);

console.log(localStorage.getItem("walletFallback"));
if (networkEnv === "ALGO") {
  if (!localStorage.getItem("walletFallback")) {
    localStorage.setItem("walletFallback", "Mnemonic");
  }
  if (!localStorage.getItem("settingsWalletFallback")) {
    localStorage.setItem("settingsWalletFallback", "MyAlgoConnect");
  }
  if (localStorage.getItem("walletFallback") === "MyAlgoConnect") {
    stdlib.setWalletFallback(
      stdlib.walletFallback({
        providerEnv,
        MyAlgoConnect,
      })
    );
  } else if (localStorage.getItem("walletFallback") === "WalletConnect") {
    stdlib.setWalletFallback(
      stdlib.walletFallback({
        providerEnv,
        WalletConnect,
      })
    );
  } else {
    stdlib.setWalletFallback(
      stdlib.walletFallback({
        providerEnv,
      })
    );
  }
}

const getAmtForContract = getAmtForContractHelper(stdlib);

function Asset() {
  const { id } = useParams();
  const navigate = useNavigate();
  //if(isNaN(parseInt(id)))
  //  window.location = '/'
  const copy = useCopy();
  const initialState = {
    acc: null,
    addrs: [],
    success: false,
    confetti: false,
    drop: false,
    memo: false,
  };
  const [bal, setBal] = useState(0.0);
  const [state, setState] = useState(initialState);
  const [close, setClose] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [options, setOptions] = useState([]);
  const [holders, setHolders] = useState(null);
  const [blacklist, setBlacklist] = useState(null);
  const [memo, setMemo] = useState(null);
  const [memo2, setMemo2] = useState(null);
  const [chips, setChips] = useState([
    { index: 0, name: "ALGO", "unit-name": "ALGO" },
  ]);
  const [activeChip, setActiveChip] = useState(0);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [query, setQuery] = useState({
    TYPE: 1, // ASA
    METHOD: 1, // LIST
    SKIPCHECK: 1, // SKIP OPTIN CHECK
  });

  useEffect(() => {
    (async () => {
      setAmount(
        Math.round(
          parseFloat(
            stdlib.formatCurrency(await stdlib.balanceOf({ addr: addrEnv }))
          )
        )
      );
    })();
  }, []);
  // code to build up stored asset info in background
  // Chrome only
  /*
  useEffect(() => {
    if (!state.acc) return
    (async () => {
      let { assets } = state.acc
      for (let i in assets) {
        let asset = assets[i]
        let assetId = asset['asset-id']
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`
        let storedAsset = localStorage.getItem(key)
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo))
          }
        }
      }
    })()
  }, [state.acc])
  */

  const isOptin = ({ optin }) => optin === true;

  const toggleClose = () => setClose(!close);

  const handleChange = async ({ target }) => {
    let { name, value } = target;
    console.log({ name, value });
    switch (name) {
      case "ASSETID":
        let { id: newId = 0, decimals: DECIMALS, creator: CREATOR } = value;
        // try again to get asset info if not in option value
        if (!DECIMALS) {
          let { decimals } = await getAsset(newId);
          DECIMALS = decimals;
        }
        setQuery({
          ...query,
          [name]: newId,
          DECIMALS,
        });
        break;
      case "INFO":
      case "PASS":
      case "AMT":
      case "TYPE":
      case "METHOD":
      case "SKIPCHECK":
        value = parseInt(value);
        break;
      default:
        break;
    }
    setQuery({ ...query, [name]: value });
  };

  const handleConnect = async () => {
    try {
      console.log("Connecting ...");

      let acc;
      switch (localStorage.getItem("walletFallback") || "MyAlgoConnect") {
        case "MyAlgoConnect":
        case "WalletConnect":
          acc = await stdlib.getDefaultAccount();
          break;
        case "Mnemonic":
        default:
          let mn = window.prompt("Enter mnemonic phrase");
          console.log("asdf");
          acc = await stdlib.newAccountFromMnemonic(formatMnemonic(mn));
      }

      const addr = stdlib.formatAddress(acc.networkAccount.addr);
      localStorage.setItem("addr", acc.networkAccount.addr);

      const balAtomic = await stdlib.balanceOf(acc);
      const bal = stdlib.formatCurrency(balAtomic, 4);
      const accInfo = await getAccountInfo(addr);
      for (let i in accInfo.assets) {
        let asset = accInfo.assets[i];
        let assetId = asset["asset-id"];
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`;
        let storedAsset = localStorage.getItem(key);
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data;
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo));
          }
        }
      }
      setState({
        ...state,
        acc: {
          ...acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleRefresh = async () => {
    console.log("Refreshing ...");
    try {
      setRefreshing(true);
      const addr = stdlib.formatAddress(state.acc.networkAccount.addr);
      localStorage.setItem("addr", state.acc.networkAccount.addr);
      const balAtomic = await stdlib.balanceOf(state.acc);
      const bal = stdlib.formatCurrency(balAtomic, 4);
      const accInfo = await getAccountInfo(addr);
      for (let i in accInfo.assets) {
        let asset = accInfo.assets[i];
        let assetId = asset["asset-id"];
        let key = `${providerEnv.toLocaleLowerCase()}-asset-${assetId}`;
        let storedAsset = localStorage.getItem(key);
        if (!storedAsset) {
          let assetInfo = (await getAsset(assetId))?.data;
          if (assetInfo) {
            localStorage.setItem(key, JSON.stringify(assetInfo));
          }
        }
      }
      setRefreshing(false);
      setState({
        ...state,
        acc: {
          ...state.acc,
          ...accInfo,
        },
        addr,
        balAtomic,
        bal,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleTest = async () => {
    let addrs = splitAddrs(query.ADDRS);

    let assetId = parseInt(query.ASSETID.id);
    let { decimals, unitname } = await getAsset(assetId);
    let candidates = [];
    for (let i in addrs) {
      console.log(`Progress: ${i / addrs.length}%`);
      let addr = addrs[i];
      if (query.SKIPCHECK === 0) {
        //let accInfo = await getAccountInfo(addr)
        candidates.push({
          addr,
          optin: null, // accInfo.assets.some(el => el['asset-id'] === assetId)
        });
      } else {
        let accInfo = await getAccountInfo(addr);
        candidates.push({
          addr,
          optin: accInfo.assets.some((el) => el["asset-id"] === assetId),
        });
      }
    }
    setState({
      ...state,
      assetId,
      decimals,
      unitname,
      success: false,
      tested: true,
      addrs: candidates,
    });
  };

  const handleTableInit = async () => {
    let addrs = splitAddrs(query.ADDRS);
    let candidates = [];
    for (let i in addrs) {
      let addr = addrs[i];
      candidates.push({
        addr,
        optin: true,
      });
    }
    setState({
      ...state,
      assetId: 0,
      decimals: 6,
      unitname: "ALGO",
      success: false,
      tested: true,
      addrs: candidates,
    });
  };

  const handleExecute = async () => {
    try {
      if (
        (localStorage.getItem("walletFallback") || "Mnemonic") === "Mnemonic"
      ) {
        let yesNo = window.prompt(
          "Are you sure you want to do this (type yes and click ok button)"
        );
        if (yesNo !== "yes") return;
      }
      let amount =
        query.TYPE === 0
          ? stdlib.parseCurrency(query.AMOUNT)
          : getAmtForContract(query.AMOUNT, state.decimals);
      let addrs = [];
      for (let i in state.addrs) {
        console.log({ i });
        let el = state.addrs[i];
        let success;
        let message;
        try {
          //if (query.SKIPCHECK === 0 && (query.TYPE === 0 || isOptin(el))) {
          //await new Promise(resolve => setTimeout(resolve, 100))
          if (query.TYPE === 0) {
            await stdlib.transfer(state.acc, el.addr, amount); // Algo transfer succeeds or fails
          } else {
            await stdlib.transfer(state.acc, el.addr, amount, state.assetId); // ASA transfer succeeds or fails
          }
          state.addrs[i].success = true;
          setState({ ...state, addrs: state.addrs });
          success = true;
          message = "";
          //} else {
          //  success = false
          //  message = "Skipped: missing asset optin"
          //  state.addrs[i].success = false
          //  state.addrs[i].message = message
          //  setState({ ...state, addrs: state.addrs })
          //}
        } catch (e) {
          console.log(e);
          message = "Transfer failed"; // <-- it failed
          success = false;
          state.addrs[i].success = false;
          state.addrs[i].message = message;
          setState({ ...state, addrs: state.addrs });
        }
        addrs.push({
          ...el,
          success,
          message,
        });
      }
      setState({
        ...state,
        addrs,
        success: true,
        confetti: true,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleRetry = async () => {
    try {
      let yesNo = window.prompt(
        "Are you sure you want to do this (type yes and click ok button)"
      );
      if (yesNo !== "yes") return;
      let amount =
        query.TYPE === 0
          ? stdlib.parseCurrency(query.AMOUNT)
          : getAmtForContract(query.AMOUNT, state.decimals);
      let addrs = [];
      for (let i in state.addrs) {
        console.log({ i });
        let el = state.addrs[i];
        if (el.success) {
          addrs.push(el);
          continue;
        }
        let success;
        let message;
        try {
          //if (query.TYPE === 0 || isOptin(el)) {
          //await new Promise(resolve => setTimeout(resolve, 100))
          if (query.TYPE === 0) {
            await stdlib.transfer(state.acc, el.addr, amount); // Algo transfer succeeds or fails
          } else {
            await stdlib.transfer(state.acc, el.addr, amount, state.assetId); // ASA transfer succeeds or fails
          }
          state.addrs[i].success = true;
          setState({ ...state, addrs: state.addrs });
          success = true;
          message = "";
          //} else {
          //  success = false
          //  message = "Skipped: missing asset optin"
          //}
        } catch (e) {
          console.log(e);
          message = "Transfer failed"; // <-- it failed
          success = false;
        }
        addrs.push({
          ...el,
          success,
          message,
        });
      }
      setState({
        ...state,
        addrs,
        success: true,
        confetti: true,
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleDisconnect = () => setState(initialState);

  const handleProviderSelect = (providerEnv) => {
    localStorage.setItem("providerEnv", providerEnv);
    window.location.reload();
  };

  const handleSupport = async () => {
    if (!state.acc) {
      alert("Wallet must be connected before sending donation");
      return;
    }
    let addr = addrEnv;
    let inputAmt = window.prompt("Enter ALGO amount to send");
    if (!inputAmt) return;
    let amt = stdlib.parseCurrency(inputAmt);
    stdlib
      .transfer(state.acc, addr, amt)
      .then(() => alert("ALGO recieved! Thank you!"));
  };

  const handleFetch = async () => {
    let assetId = parseInt(query.ASSETID.id);
    let { decimals, unitname } = await getAsset(assetId);
    let addrs = (await getAllAssetBalances(assetId)).map(
      ({ address: addr }) => ({ addr, optin: true })
    );
    setState({
      ...state,
      assetId,
      decimals,
      unitname,
      addrs,
      success: false,
      tested: true,
    });
  };

  const { width, height } = useWindowSize();

  /* autocomplete */
  const eventHandler = async (e) => {
    setLoading(true);
    let search = e.target.value;
    if (!search) return;
    let ids = splitAddrs(search);
    let list = [];
    for (let i in ids) {
      let id = parseInt(ids[i]);
      let assetInfo = await getAsset(id);
      console.log(assetInfo);
      console.log(id);
      let holders;
      let next;
      do {
        let params = {
          limit: 1000,
          "currency-greater-than": 0,
        };
        if (next) {
          params.next = next;
        }
        let holders = await getHolders(id, params);
        console.log(holders);
        list.push(
          holders.accounts.map((el) => {
            let assets = el.assets.filter((el) => el["asset-id"] === id);
            let asset = assets.pop();
            return {
              ...el,
              asset: assetInfo,
              amount: asset.amount,
            };
          })
        );
        next = holders["next-token"];
      } while (next);
    }
    setLoading(false);
    setList(list.flatMap((el) => el).sort((a, b) => b.amount - a.amount));
  };

  const debouncedEventHandler = useMemo(() => debounce(eventHandler, 300), []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Container className="pb-5">
      {state.confetti && <Confetti width={width} height={height} />}
      <Modal
        open={!close}
        onClose={toggleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Settings modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      <Row className="mt-5">
        <Col>
          <h1
            style={{
              fill: "pink",
              color: "black",
              background: "white",
            }}
            className="text-center"
          >
            <img
              style={{
                height: "44px",
                verticalAlign: "baseline",
                background:
                  providerEnv === "MainNet" ? "deepskyblue" : "darkorange",
                borderRadius: "50px",
              }}
              src={drop}
            />
            <span
              style={{
                color: providerEnv === "MainNet" ? "red" : "green",
              }}
            >
              Algo
            </span>
            Drop
            <span
              style={{
                fontSize: "12px",
              }}
            >
              v0.0.5
            </span>
          </h1>
        </Col>
        <Col className="text-center" xs={12}>
          This runs on
          <img
            style={{
              height: "30px",
            }}
            src={logo}
            alt="Algorand log"
          />
          <ProviderSelector />
        </Col>
      </Row>
      {!state.acc && (
        <Row className="mt-5 role role-participant">
          <Col>
            <ButtonGroup>
              <Dropdown as={ButtonGroup}>
                <Button onClick={handleConnect}>Connect</Button>
                <Dropdown.Toggle split id="dropdown-basic" />
                <Dropdown.Menu>
                  {false && (
                    <Dropdown.Item
                      onClick={() => {
                        localStorage.setItem("walletFallback", "Mnemonic");
                        window.location.reload();
                      }}
                    >
                      {localStorage.getItem("walletFallback") ===
                        "Mnemonic" && <CheckIcon />}
                      Mnemonic
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    onClick={() => {
                      localStorage.setItem("walletFallback", "MyAlgoConnect");
                      window.location.reload();
                    }}
                  >
                    {localStorage.getItem("walletFallback") ===
                      "MyAlgoConnect" && <CheckIcon />}
                    My Algo Connect
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      localStorage.setItem("walletFallback", "WalletConnect");
                      window.location.reload();
                    }}
                  >
                    {localStorage.getItem("walletFallback") ===
                      "WalletConnect" && <CheckIcon />}
                    Wallet Connect
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </Col>
        </Row>
      )}
      {state.acc && (
        <Row className="mt-5">
          <Col className="mt-5" xs={12}>
            Connected as{` `}
            <Dropdown as={ButtonGroup}>
              <Button
                style={{ fill: "white" }}
                variant="dark"
                onClick={() => copy(state.addr)}
              >
                {formatCompactAddress(state.addr)} |{" "}
                <Icon
                  style={{ verticalAlign: "baseline" }}
                  size={11}
                  name="algo"
                />{" "}
                {state.bal}
              </Button>
              <Dropdown.Toggle variant="dark" split id="dropdown-basic" />
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleDisconnect}>
                  Disconnect
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {` `}
            <span>
              {!refreshing ? (
                <RefreshIcon
                  style={{ verticalAlign: "sub" }}
                  fontSize="small"
                  onClick={handleRefresh}
                />
              ) : (
                <Spinner animation="border" variant="secondary" size="sm" />
              )}
            </span>
          </Col>
        </Row>
      )}
      <Row className="mt-5">
        <h2>Support Dev</h2>
        <Col xs={12} sm={6}>
          AlgoDrop makes sending ALGO and ASAs easy. Support development with
          donations or participation in no-loss ASA mining for{" "}
          <a
            href="https://algoexplorer.io/asset/524904247"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            DROPS
          </a>
          .
        </Col>
        <Col>
          <Row>
            <Col xs={12}>
              <Button
                className="w-100"
                variant="success"
                onClick={handleSupport}
              >
                Donate ALGO to AlgoDrop
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                disabled
                className="w-100"
                variant="secondary"
                onClick={() => {}}
              >
                Stake ALGO on AlgoDrop for DROPS
              </Button>
            </Col>
            <Col xs={12}>
              <Button
                disabled
                className="w-100"
                variant="secondary"
                onClick={() => {}}
              >
                Donate ASA to AlgoDrop
              </Button>
            </Col>
          </Row>
        </Col>
        {false && (
          <>
            <Row className="mt-5">
              <h2>Donations</h2>
              <Col xs={12} sm={6}>
                Donations received as of {new Date().toString()}
              </Col>
              <Col xs={12} sm={6}>
                <h3 className="mt-3 text-center">
                  <Icon name="algo" size={32} />
                  {` `}
                  <span
                    style={{
                      fontSize: "48px",
                      verticalAlign: "middle",
                    }}
                  >
                    {amount === 0 ? "-" : amount}
                  </span>
                </h3>
              </Col>
            </Row>
            <Row className="mt-5">
              <h2>Use of Donations</h2>
              <Col xs={12}>
                As of {new Date().toString()}, AlgoDrop does not intend to use
                ALGO or any ASA received through donations or stake
                participation.
              </Col>
            </Row>
          </>
        )}
      </Row>
    </Container>
  );
}

export default withMenu(Asset);
